import { TFunction } from 'i18next';

export const getStateColumns = (t: TFunction) => [
  {
    title: t('Settings.terms.columns.name'),
    dataIndex: 'name',
    key: 'name',
    width: '50%',
  },
  {
    title: t('Settings.terms.columns.status_access'),
    dataIndex: 'status_access',
    key: 'status_access',
    width: '25%',
  },
  {
    title: t('Settings.terms.columns.signed_date'),
    dataIndex: 'signed_date',
    key: 'signed_date',
    width: '25%',
  },
];
