import { Modal, Tabs, TabsProps } from 'antd';
import React from 'react';
import classes from './ModalNDA.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import RowLoader from 'components/Sceletons/RowLoader';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';
import NDAAgreement from 'components/NDAElements/NDAAgreement';
import NDADocuments from 'components/NDAElements/NDADocuments';
import NDAReport from 'components/NDAElements/NDAReport';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import StateOfTerm from '../StateOfTerm/StateOfTerm';

type ModalNDAType = {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalNDA = ({open, setIsOpen}: ModalNDAType) => {
  const { dataRoom, isLoadingRooms } = useAppSelector((state) => state.dataRoom);
  const { userPermissions } = useAppSelector((state) => state.userData);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  
  const userAcceptNDA = dataRoom?.user_status === 2;
  const canViewLogs = userPermissions.can_view_logs;
  const canViewState = userPermissions.can_edit_room_settings && userAcceptNDA;

  const formatNDA = dataRoom?.agreement_file?.split('.')?.reverse()[0];  
  const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';

  React.useEffect(() => {
    if (dataRoom?.agreement_file) {
      const fileInfo = dataRoom?.agreement_file!.split(/\.\b/); 
      const fileID = dataRoom.agreement_id;
      dispatch(
        fetchDocumentToViewer({
          token: fileID,
          id: dataRoom?.id!,
          entity: fileID,
          extension: fileInfo[1],
          is_agreement: true,
          action: 'nda_view',
          locale: i18n.language,
        })
      );
    }
  }, [dataRoom?.agreement_file]);

  const TabsData: TabsProps['items'] = [
    {
      key: 'agreement',
      label: t('Confirm.agreement'),
      children: <NDAAgreement userAcceptNDA={userAcceptNDA}/>,
    },
  ];

  canViewState && TabsData.push({
    key: 'state',
    label: t('Settings.terms.state.title'),
    children: <StateOfTerm />,
  });

  canViewLogs && TabsData.push(
    {
      key: 'reports',
      label:  t('Confirm.reports'),
      disabled: !userAcceptNDA,
      children: <NDAReport />,
    }
  );

  TabsData.push(
    {
      key: 'documents',
      label: t('Confirm.documents'),
      disabled: !userAcceptNDA,
      children: <NDADocuments />,
    },
  );

  const titleFile = (
    <div>
      {t('Confirm.ndaTitle')} 
    </div>
  );
  

  const getStatusIcon = (status?: number) => {
    switch(status) {
      case 3:
        return <CloseOutlined className={classes.rejectIcon}/>
      case 2:
        return <CheckOutlined className={classes.acceptIcon}/>
      default: 
        return <ClockCircleOutlined className={classes.notAcceptIcon}/>
    };
  };

  const getClassOfWrap = (status?: number) => {
    switch(status) {
      case 3:
        return classes.reject
      case 2:
        return classes.accept
      default: 
        return classes.nonAccept
    };
  };

  const getStatusText = (status?: number) => {
    switch(status) {
      case 3:
        return t('Settings.terms.state.rejected')
      case 2:
        return <>
          <div>{t('Confirm.signedByMe')}</div>
          <div className={classes.date}>{t('Confirm.date')}</div>
          <div className={classes.dateValue}>{dataRoom?.user_status_change_at}</div>
        </>
      default: 
        return t('Settings.terms.state.nonAccepted')
    };
  }

  return (
    <Modal
      centered
      width={'90%'}
      onCancel={() => setIsOpen(false)}
      open={open}
      className={classes.modalWrap}
      title={titleFile} 
      footer={null}
    >
      {dataRoom?.agreement_enable && <>
        <div className={classes.titleWrap}>
        <div className={classes.statusWrap}>
          <div className={classes.docName}>
            <div style={{ fontSize: 20 }}>{documnentIcons[icon as keyof typeof documnentIcons]}</div>
            <div style={{ paddingLeft: 6 }}>{dataRoom?.agreement_file}</div>
          </div>
          
          <div className={cn(classes.dateWrap, getClassOfWrap(dataRoom?.user_status))}>
            {
              isLoadingRooms || !dataRoom?.user_status
                ? <RowLoader width={30} padding={'0 0 0 15px'} height={28}/>
                : getStatusIcon(dataRoom?.user_status)
            }
          </div>
        </div>
        
        {
          isLoadingRooms || !dataRoom?.user_status
            ? <RowLoader width={220} padding={'0 0 0 30px'} height={28}/>
            : <div className={cn(classes.dateWrap, getClassOfWrap(dataRoom?.user_status))}>
              { getStatusText(dataRoom?.user_status) }
            </div>
        }
      </div>
    
      <Tabs className={classes.tabs} defaultActiveKey={'agreement'} items={TabsData} type='card' />

      </>}
    </Modal>
  );
}

export default ModalNDA;
