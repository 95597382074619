import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, message, Modal, Select, Tooltip, Typography } from 'antd';
import api from 'api';
import { NewInvitesResponse } from 'api/users';
import Invitations from 'components/Invitations/Invitations';
import InvitationsSettings from 'components/Invitations/InvitationsSettings';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { getRolesDictionary } from 'helpers/rolesDictionary';
import { IInvitations } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchGroupsAndUsers, { defaultGroups } from 'store/reducers/usersGroupCreator';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import classes from './InviteUsersModal.module.scss';

const { Paragraph } = Typography;

type InviteUsersModalProps = {
  isOpen: boolean;
  setIsInviteUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type valuesType = {
  role: string;
  group: string;
};

export default function InviteUsersModal ({ isOpen, setIsInviteUserOpen }: InviteUsersModalProps) {
  const [invitations, setInvitations] = React.useState<IInvitations[]>([]);
  const [isInvitationsSettings, setIsInvitationsSettings] = React.useState(false);
  const [isLaoding, setIsLoading] = React.useState(false);
  const [choosenRole, setChoosenRole] = React.useState<string | null>(null);
  const [choosenGroup, setChoosenGroup] = React.useState<string>();
  const [availableGroups, setAvailableGroups] = React.useState<{value: string; label: string}[]>([]);
  const [isGroupsLoading, setIsGroupsLoading] = React.useState(false);
  const [invatationsResponceAvailable, setInvatationsResponceAvailable] = React.useState(false);
  const [invatationsResponce, setInvatationsResponce] = React.useState<NewInvitesResponse>();
  const [tags, setTags] = React.useState<string[]>([]);
  const [emailIsValid, setEmailIsValid] = React.useState(false);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const rolesDictionary = getRolesDictionary(t);

  const { groups, selectedGroup, rolesPermissions, pendingInviteUsers } = useAppSelector((state) => state.dataUsers);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  
  const usersEmail = [
    ...(dataRoom?.available_users?.map((user) => user.email.toLocaleLowerCase()) || []),
    ...pendingInviteUsers.map(({ email }) => email.toLocaleLowerCase()),
  ];
    
  const groupsOfRoom = groups?.map(group => ({
    value: group.id,
    key: group.group_name,
    label: defaultGroups.includes(group.name)
      ? t(`Users.table.${group.name}`)
      : group.name,
  }));

  const defaultGroup = groupsOfRoom.find(group => group.value === selectedGroup?.group_id)
  || groupsOfRoom.find(group => group.key === 'user')
  || groupsOfRoom[0];      
  const groupPermissions = groups.find(({ id }) => id === choosenGroup)?.role?.permissions;

  React.useEffect(() => {
    setChoosenGroup(defaultGroup.value)
  }, [])

  React.useEffect(() => {
    setAvailableGroups(groupsOfRoom);
    choosenRole && getActualGroups();
  }, [choosenRole]);

  React.useEffect(() => {
    const newTags = tags.filter(
      (tag: string) =>
        !Boolean(invitations.find((invitation: IInvitations) => invitation.email === tag))
    );
    setInvitations([
      ...invitations,
      ...newTags.map((tag: string) => ({
        email: tag,
        fullName: '',
        organization: '',
        position: '',
        phone: '',
      })),
    ]);
  }, [tags]);

  const nameOfChoosenGroup = groupsOfRoom.find(group => group.value === choosenGroup)?.label;
  
  const defaultValues = {
    group: defaultGroup.value,
  };

  const onCancel = () => {
    setIsInviteUserOpen(false);
    setInvitations([]);
  };

  const getActualGroups = async () => {
    setIsGroupsLoading(true);
    try {
      const groups = await api.getGroupsByRole({roomId: dataRoom?.id!, role: choosenRole!});
      const groupsForSelect = groups.data?.map(group => ({
        value: group.id,
        label: group.name,
      }));
      const isChoosenGroupAvailable = choosenGroup ? groupsForSelect.map(group => group.value).includes(choosenGroup) : false
      setAvailableGroups(groupsForSelect);
      form.setFieldValue('group', isChoosenGroupAvailable ? choosenGroup : groupsForSelect[0].value)
    } catch (e) {
      const currentRole = rolesDictionary.find(role => role.value === choosenRole)?.label
      message.error(`${t('Users.modals.invite.errorGettingGroups')} '${currentRole}'`, 10);
      setChoosenRole(null);
      form.setFieldValue('role', null)
      setAvailableGroups(groupsOfRoom);
    } finally {
      setIsGroupsLoading(false);
    }
  }

  const onFinish = async (values: valuesType) => {
    if (invitations.length >= 1) {
      try {
        setIsLoading(true);
        const targetGroup = groups.find(group => group.id === values.group);
        const emails = invitations.map(({ email }) => email.toLocaleLowerCase());
        const response = await api.getNewInvite({
          emails, room_id: dataRoom?.id!,
          group_id: values.group,
          type: 'common',
          details: {emails, group_name: targetGroup?.name, group_role: targetGroup?.role?.name || values.role || '-', group_id: targetGroup?.id,}
        });
        setInvatationsResponce(response.data);
        const successMessage = emails.length === 1 ? t('Documents.success.inviteUser') : t('Documents.success.inviteUser');
        message.success(`${successMessage}: ${emails.join(', ')}`, 10);
        setInvatationsResponceAvailable(true);
        if (dataRoom) {
          const usersAction = (await dispatch(fetchGroupsAndUsers({ room_id: dataRoom?.id }))) as any;
          dispatch(setDataRoom({ ...dataRoom, available_users: usersAction.payload.users }));
        }
      } catch (err) {
        message.error(t('Documents.error.inviteUsers'));
      } finally {
        setIsLoading(false);
      }
    } else {
      message.warning(t('Documents.error.inviteUsersEmpty'))
    }
  };  

  const statusIcons = {
    true: <CheckOutlined style={{color: 'green', paddingRight: 10, fontSize: 16}}/>,
    false: <CloseOutlined style={{color: 'red', paddingRight: 10, fontSize: 16}}/>
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      // transitionName={''}
      className={classes.modal}
      okText={t('Users.modals.invite.submitText')}
      title={invatationsResponceAvailable ? t('Users.modals.invite.title2') : t('Users.modals.invite.title')}
      width={isInvitationsSettings ? '70%' : '60%'}
      confirmLoading={isLaoding}
      onOk={form.submit}
      cancelText={invatationsResponceAvailable ? t('main.closeButton') : t('main.cancelButton')}
      okButtonProps={{className: invatationsResponceAvailable ? classes.none : 'simple', disabled: !emailIsValid}}
      centered
    >
      {invatationsResponceAvailable 
        ? <div>
          <div className={classes.invatationsWrap}>
            <div className={classes.columnsInvatations}>
              <div>{t('Users.modals.invite.settings.email')}</div>
              <div>{t('Users.modals.invite.invitationLink')}</div>
              <div />
            </div>
            <div className={classes.subWrap}>{invatationsResponce?.map((invitation: any, index: number) => {
                return (
                <div key={`settings_${index}`} className={classes.columnsInvatations}>
                  <div className={classes.emailValue}>
                    {invitation.email} :
                  </div>
                  {invitation.link ?
                  <>
                    <div className={classes.textValue}>{invitation.link}</div>
                    <Paragraph
                      className={classes.copyIcon}
                      copyable={{
                        text: invitation.link,
                        tooltips: [t('Users.modals.invite.tooltip.copy'), t('Users.modals.invite.tooltip.copied')],
                      }}
                    />
                  </>
                : <div className={classes.textValue}>{`${t('Users.modals.invite.userIsAdded')} ${nameOfChoosenGroup}`}</div>}
                </div>
              )})
            }</div>
          </div>
        </div>

        : <div className={!isInvitationsSettings ? classes.modal_invite : classes.modal_moreInfo}>
          <Form form={form} initialValues={defaultValues} layout='vertical' onFinish={onFinish}>
            <div className={classes.form_row}>

              <Form.Item style={{width: '100%'}} name='group' label={t('Users.modals.invite.labels.role')} required>
                <Select
                  className={classes.select}
                  options={availableGroups}
                  loading={isGroupsLoading}
                  disabled={isGroupsLoading}
                  onChange={setChoosenGroup}
                />
              </Form.Item>

              <div className={classes.permissions}>
                <div className={classes.permissions_title}>{t('personalInfo.permissions')}</div>
                <div className={classes.permissions_itemValue}>
                  {rolesPermissions?.map((permission) => (
                    <div key={permission.id}>
                      <Tooltip title={permission.description}>
                        {statusIcons[String(groupPermissions?.includes(permission.type)) as keyof typeof statusIcons]}
                        {permission.name}
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
              
            </div>

            {isInvitationsSettings ? (
              <div>
                <InvitationsSettings invitations={invitations} setInvitations={setInvitations} />
                <div
                  onClick={() => setIsInvitationsSettings(!isInvitationsSettings)}
                  className={classes.personaldetails}
                >
                  {t('main.backButton')}
                </div>
              </div>
            ) : (
              <div>
                <Invitations setEmailIsValid={setEmailIsValid} tags={tags} setTags={setTags} setInvitations={setInvitations}  usersEmail={usersEmail}/>
                <div
                  onClick={() => tags.length && setIsInvitationsSettings(!isInvitationsSettings)}
                  className={classes.personaldetails}
                  style={!tags.length ? { cursor: 'not-allowed' } : undefined}
                >
                  {t('Users.modals.invite.details')}
                </div>
              </div>
            )}
          </Form>
        </div>
      }

      { isLaoding &&
        <UploadingComponent />
      }
    </Modal>
  );
};
