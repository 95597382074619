import React, { useState } from 'react';
import { Input, Modal, message, Form, InputRef, Collapse } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsFolderCreateOpen } from 'store/slices/dataRoomSlice';
import classes from './NewFolderModal.module.scss';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { setUserPermissions } from 'store/slices/dataPermissionsSlice';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { CaretRightOutlined } from '@ant-design/icons';
import PermissionsTable from 'components/PermissionsTable/PermissionsTable';
import { IFolder } from 'interfaces';
import fetchGroupsAndUsers, { defaultGroups } from 'store/reducers/usersGroupCreator';
import { allPermissions } from 'helpers/createPermissionTableData';
import cn from 'classnames';
import { setGroups } from 'store/slices/dataUsersSlice';

type NewFolderModalType = {
  roomId: string;
  open: boolean;
  resetNotes: () => void;
  reloadSelected: () => void;
};

interface IGroupForPermissions extends IFolder {
  group_name?: string;
}

export default function NewFolderModal ({ roomId, open, resetNotes, reloadSelected }: NewFolderModalType) {
  const [form] = Form.useForm();
  const textInput = React.useRef<InputRef | null>(null);

  const [isFolderCreating, setIsfolderCreating] = useState<boolean>(false);
  const [permissionsByRole, setPermissionsByRole] = React.useState<IGroupForPermissions[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

  const { userFoldersPermissions, userRootPermissions } = useAppSelector((store) => store.permissions);
  const { currentFolder } = useAppSelector((store) => store.documents);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { groups, users } = useAppSelector((store) => store.dataUsers);
  const { userData } = useAppSelector((store) => store.userData);

  const currentFolderId = currentFolder[0] ? currentFolder[currentFolder.length - 1]?.id : null;

  const needToSetPermissions = currentFolderId === null && activeKey === undefined;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    textInput.current?.focus();
  }, []);

  React.useEffect(() => {
    activeKey === 'permissions' && !groups[0] && dispatch(fetchGroupsAndUsers({room_id: dataRoom?.id!}));
  }, [activeKey])

  React.useEffect(() => {
    const meInThisRoom = users.find(user => user.email === userData?.email);
    const preparedAndSortGroups = groups[0] && groups
      .map(group => {        
        const grantAllPermissions = group.name === 'full_administrator' || meInThisRoom?.group[0] === group.group_id;
        const localizedGroupName = defaultGroups.includes(group.name!) ? t(`Users.table.${group.name}`) : group.name;
        const nameWithPrompt = meInThisRoom?.group[0] === group.group_id ? `${localizedGroupName} (${t('Documents.modals.newFolder.myRole')})` : localizedGroupName;        
        return {
          ...group,
          name: nameWithPrompt,
          group_name: localizedGroupName,
          permissions: grantAllPermissions ? allPermissions : [],
          can_edit_permissions: group.name === 'full_administrator' ? false : true,
          type: 'role',
        }
      })
      .sort((a, b) => {
        if(defaultGroups.includes(a.name)) return -1;
        if(defaultGroups.includes(b.name)) return 1;
        return a.name.localeCompare(b.name)
      });
    
    groups[0] && setPermissionsByRole(preparedAndSortGroups);
  }, [groups]);

  const onFolderCreate = async (values: any) => {    
    setIsfolderCreating(true);
    try {
      const { data: { id, name }} = await api.createFolder({
        name: values.folder_name,
        room: roomId,
        folder_id: currentFolderId!,
        details: {folder_name: values.folder_name}
      });
      message.success(t('Documents.success.createFolder'));

      const rolesWithPermissions = permissionsByRole.filter(role => role.permissions && role.permissions[0]);

      await Promise.all(rolesWithPermissions.map(group => {
        return api.setFolderPermission({
          permission_types: group.permissions as string[],
          folder_id: id!,
          room_id: dataRoom?.id!,
          group_id: group.id!,
          details: {
            before: [],
            after: group.permissions,
            folder_id: id!,
            folder_name: name,
            group_name: group.group_name,
            group_role: group.group_name,
            group_id: group.id,
          }
        })
        .then(value => {
          message.success(`${t('Documents.error.permissionsSuccessAssigned')} '${group.group_name}'`);
        })
        .catch(e => {
          message.warning(`${t('Documents.error.permissionsFailedRole')} '${group.group_name}'`);
        })
      }))      
      dispatch(setIsFolderCreateOpen(false));

      const meInThisRoom = users.find(user => user.email === userData?.email);      
      const myRolePermissions = permissionsByRole.find(group => group.id === meInThisRoom?.group[0]);
      const newFolderWithNeededPermissions = {
        id,
        name,
        can_edit_permissions: true,
        type: 'folder',
        key: `folder ${id} ${(userFoldersPermissions?.length || 0) + 1}`,
        children: null,
        clicked: false,
        permissions: myRolePermissions?.permissions
      }      

      // добавляем все права пользователю на только что созданную папку, если это корневая папка
      currentFolderId === null && dispatch(setUserPermissions({
        documents: [],
        rootFolder: userRootPermissions,
        folders: [
          ...userFoldersPermissions || [],
          newFolderWithNeededPermissions
        ],
      }));

      reloadSelected();
      resetNotes();
    } catch (err) {
      const error = err as any;
      if (error?.response?.data?.message === 'Folder with this name already exists') {
        message.error(t('Documents.error.existsFolder'));
        return;
      }
      message.error(error.message || error.response?.detail[0].msg || t('Documents.error.newFolder'));
    } finally {
      // setTimeout(() => {
      //   dispatch(fetchAvailableDocuments({ roomId: dataRoom?.id!, folderId: currentFolderId! }));
      // }, 1000);
      setIsfolderCreating(false);
    }
  };
  const onCancelClick = () => dispatch(setIsFolderCreateOpen(false));  

  return (
    <Modal
      title={t('Documents.modals.newFolder.title')}
      centered
      width={currentFolderId === null && activeKey === 'permissions' ? '90%' : '50%'}
      open={open}
      onOk={() => needToSetPermissions ? setActiveKey('permissions') : form.submit()}
      onCancel={onCancelClick}
      className={cn(classes.createFolderModal, (groups[0] || activeKey === 'permissions') ? classes.transition : null)}
      okButtonProps={{ loading: isFolderCreating }}
      okText={needToSetPermissions ? t('Documents.modals.newFolder.next') : t('Documents.modals.newFolder.create')}
      cancelText={t('main.cancelButton')}
    >
      <Form form={form} onFinish={(values) => needToSetPermissions ? setActiveKey('permissions') : onFolderCreate(values)}>
        <Form.Item
          className={classes.folderName}
          name='folder_name'
          rules={[{ required: true, message: t('Documents.modals.newFolder.enter_folder_name')}]}
        >
          <Input
            ref={textInput}
            count={{
              show: true,
              max: 80,
            }}
            maxLength={80}
            placeholder={t('Documents.modals.newFolder.placeholder')}
          />
        </Form.Item>
      </Form>

      {currentFolderId === null && <Collapse
        items={[{
          key: 'permissions',
          label: t('Documents.modals.newFolder.setPermissions'),
          children: <PermissionsTable
            dataWithRootFolder={permissionsByRole}
            setDataWithRootFolder={setPermissionsByRole}
            heightTable={`calc(100vh - 220px)`}
            scrollTable={`calc(100vh - 405px)`}
            scrollLoader={'calc(100vh - 550px)'}
            parent={'newFolderModal'}
          />,
        }]}
        ghost
        onChange={(keys) => setActiveKey(keys[0])}
        activeKey={activeKey}
        expandIconPosition='end'
        expandIcon={({ isActive }) => <CaretRightOutlined className={classes.expandIcon} rotate={isActive ? 90 : 0} />}
      />}

      {isFolderCreating &&
        <UploadingComponent />
      }
    </Modal>
  );
};
