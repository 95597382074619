import React from 'react';
import classes from './LogsDetailsModal.module.scss';
import { Col, Modal, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsLogsDetailsOpen } from 'store/slices/dataRoomSlice';
import { useTranslation } from 'react-i18next';
import { getMockActions } from 'helpers/getMockActions';
import MapView from 'components/Map';
import { prepareLogForDisplay } from './prepareLogForDisplay';

type LogsDetailsModalType = {
  open: boolean;
};

export default function LogsDetailsModal({ open }: LogsDetailsModalType) {
  const { choosenLog } = useAppSelector((store) => store.logs);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const staticRuT = React.useMemo(() => i18n.getFixedT('ru'), []);
  const ruActions = React.useMemo(() => getMockActions(staticRuT, t), [i18n.language]);
  const displayedData = choosenLog && ruActions && prepareLogForDisplay(choosenLog, t, ruActions);
  const exclusionKeys = ['key', 'details', 'type', 'size', 'timestamp'];
    
  const onCancelClick = () => dispatch(setIsLogsDetailsOpen(false));

  const mapModalCases = {
    trigger: {
      modalWidth: '90%',
      colSpan: 12,
      render: (
        <Col span={12}>
          <MapView choosenLog={choosenLog!} zoom={10} position={[0, 0]} />
        </Col>
      ),
    },
    default: {
      modalWidth: '70%',
      colSpan: 24,
      render: null,
    },
  };
  const modalLogCase = (choosenLog?.type! === 'trigger' ? 'trigger' : 'default') as keyof typeof mapModalCases;

  return (
    <Modal
      title={t('Logs.logsModal.title')}
      width={mapModalCases[modalLogCase].modalWidth}
      centered
      open={open}
      onCancel={onCancelClick}
      className={classes.logsDetailsModal}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={t('Logs.logsModal.close')}
    >
      <Row className={classes.wrap}>
        {mapModalCases[modalLogCase].render}

        <Col span={mapModalCases[modalLogCase].colSpan}>
          <div className={classes.contentWrap}>
            {displayedData?.map((row) => {
              return (
                !exclusionKeys.includes(row[0]) && (
                  <div className={classes.rowDetails} key={row[0]}>
                    <div className={classes.key}>{row[0]}</div>
                    <div className={classes.value}>
                      {row[1] instanceof Array
                        ? row[1].map((details, i) => (
                            <div key={details[0] + details[1] + i}>
                              {details[0] && <span>{`${details[0]}: ${details[1]}`}</span> }
                              <br />
                            </div>
                          ))
                        : row[1]}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
