import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { deleteCookie, getCookie, setCookie } from 'helpers/cookies';
import { jwtDecode } from 'jwt-decode';
import groups from './groups';
import users from './users';
import rooms from './rooms';
import documents from './documents';
import logs from './logs';
import folders from './folders';
import confidential from './confidential';
import organizations from './organizations';
import agreement from './agreement';
import notifications from './notifications';
import roles from './roles';
import notes from './notes';
import chats from './chats';
import auth from './auth';
import share from './share';
import signing from './signing';
import tariffs from './tariffs';
import terms from './terms';
import versions from './versions';
import blobErrorToObject from 'helpers/blobErrorToObject';

const requestTemplate = axios.create({
  // @ts-ignore
  baseURL: window.REACT_APP_SERVER_API !== 'REPLACE_REACT_APP_SERVER_API' ? window.REACT_APP_SERVER_API : process.env.REACT_APP_SERVER_API || 'http://localhost:8000/api',
  withCredentials: true,
})

requestTemplate.interceptors.request.use(async(config: InternalAxiosRequestConfig) => {
  let accessToken = getCookie('athena-token');
  let decodeInfo: any;
  if (accessToken) {
    try {
      decodeInfo = jwtDecode(accessToken);
    } catch (error) {
      Promise.reject('Error decoding token');
    }
    const expTime = decodeInfo?.exp && decodeInfo.exp * 1000;
    const curTime = new Date().getTime();
  
    if (expTime && expTime - curTime <= 50000) {
      const refresh_token = getCookie('refresh_token');
      if (refresh_token) {
        try {
          const freshTokens = await auth.refreshTokens({ refresh_token });        
          setCookie('athena-token', freshTokens.data.access_token, 1);
          freshTokens.data.refresh_token && setCookie('refresh_token', freshTokens.data.refresh_token, 1);
          accessToken = freshTokens.data.access_token;
        } catch (error) {
          Promise.reject('Error refreshing tokens');
          deleteCookie('athena-token');
          deleteCookie('refresh_token');
          window.location.href = `/login`;
        };
      };
    };
  
    config.headers!.Authorization = `Bearer ${accessToken}`;
  } else {
    setCookie('athena-token', '', 1);
  }
  return config;
});

requestTemplate.interceptors.response.use(( response: AxiosResponse ) => {
  return response
}, async (error: AxiosError<any>) => {
  if (error.response?.data?.detail === 'Invalid access token') {
    deleteCookie('athena-token');
    deleteCookie('refresh_token');
    window.location.href = `/login`;
  }
  if (error?.response?.data.constructor.name === 'Blob'){
    error.response.data = await blobErrorToObject(error?.response?.data)
  };
  return Promise.reject(error)
});

const api = {
  ...groups,
  ...users,
  ...rooms,
  ...documents,
  ...logs,
  ...folders,
  ...confidential,
  ...organizations,
  ...agreement,
  ...notifications,
  ...notes,
  ...chats,
  ...roles,
  ...share,
  ...signing,
  ...terms,
  ...tariffs,
  ...versions,
}

export { requestTemplate };
export default api
