import dayjs from 'dayjs';
import en from 'dayjs/locale/en';

export const dateFormatCase = {
  international: 'DD-MM-YYYY HH:mm:ss',
  usa: 'MM-DD-YYYY hh:mm a',
  internationalHours: 'HH:mm:ss',
  usaHours: 'hh:mm a',
};

const getDateWithTimeZone = (date: string | number, type: keyof typeof dateFormatCase, isEnLocale?: boolean) => {
  const timeOffset = dayjs().utcOffset();
  const unixTimeWithTimeZone = dayjs(date).valueOf() + timeOffset * 60 * 1000;
  return isEnLocale
    ? dayjs(unixTimeWithTimeZone).locale(en).format(dateFormatCase[type])
    : dayjs(unixTimeWithTimeZone).format(dateFormatCase[type]);
};

export default getDateWithTimeZone;
