import { message } from 'antd';
import api from 'api';
import { socketUrl } from 'api/ws';
import { removeItemsFromLocalStorage } from 'helpers/cleanLocalStorage';
import { deleteCookie, getCookie, setCookie } from 'helpers/cookies';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchChatData, {
  fetchChatInfo,
  fetchUnviewedAmount,
  setMessagesToLocalStorage,
  setRejectMessages,
  switchChatMessagesStatus,
} from 'store/reducers/chatCreator';
import fetchStatusDocuments, {
  fetchDeleteDocument,
  fetchDeleteFolder,
  fetchDocumentMove,
  fetchDocumentUpdate,
  fetchFoldersPermissionsUpdate,
} from 'store/reducers/documentsSocketsCreator';
import { fetchRoomNotifications } from 'store/reducers/roomsCreator';
import { updateRooms } from 'store/reducers/userDataCreator';
import { fetchUsersOnSokcet } from 'store/reducers/usersGroupCreator';
import {
  clearAllChatData,
  setChatMessage,
  setChatStatus,
  setCheckingMessages,
  setIsLoadedSession,
} from 'store/slices/chatsSlice';
import { setCurrentFolder, setDocuments } from 'store/slices/dataDocumentsSlice';
import { setDataForPermissionTable, setUserPermissions } from 'store/slices/dataPermissionsSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { resetOrgsAndSides } from 'store/slices/dataSidesSlice';
import { setGroupChoosen, setGroups, setUserAndData } from 'store/slices/dataUsersSlice';
import { resetUserPermissions, setUserData } from 'store/slices/userDataSlice';
import { setIsOpenSuccessfulPayment } from 'store/slices/windowStateSlice';

export default function WorkerComponent() {
  const [isPausedWS, setIsPausedWS] = React.useState(false);
  const workerRef = React.useRef<SharedWorker | null>(null);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const locale = i18n.language || 'en';

  const { userData } = useAppSelector((store) => store.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const {
    chatStatus,
    chatMessage,
    selectedChat,
    isLoadedSession,
    checkingMessages,
    listStatus,
    viewsMessagesIds,
    roomChatLoading,
  } = useAppSelector((store) => store.chat);

  const clearRedux = () => {
    dispatch(setDataRoom(null));
    dispatch(clearAllChatData());
    dispatch(setDocuments({ documents: [] }));
    dispatch(resetUserPermissions());
    dispatch(setUserAndData({ users: [], dataUserTable: [] }));
    dispatch(setGroups([]));
    dispatch(setGroupChoosen(null));
    dispatch(setCurrentFolder([]));
    dispatch(setDataForPermissionTable({ documents: [] }));
    dispatch(setUserPermissions(null));
    dispatch(setUserData(null));
    dispatch(resetOrgsAndSides());
    removeItemsFromLocalStorage();
  };

  const chatCases = {
    pending: (message: any) => {},
    rejected: (message: any) => {},
    send: ({ status, timestamp, ...message }: any) => {
      const data = { ...message, status, timestamp, type: listStatus };
      const newCheckingMessages = [...checkingMessages, data];
      workerRef.current?.port.postMessage({ event: 'chat', message });
      dispatch(setChatStatus('pending'));
      dispatch(setChatMessage({ id: '', status: 'pending', message: '', replied_to: null }));
      dispatch(setCheckingMessages(newCheckingMessages));
    },
  };

  const workerMessageListeners = React.useMemo(() => {
    return {
      'auth:get': (message: any) => {
        console.log('Session is authed', message);
      },

      new_chat: (data: any) => {
        console.log('new chat created -', data);
        dispatch(fetchChatInfo({ chatId: data.new_chat_id, ownerId: userData?.id! }));
      },

      new_global_chat: (data: any) => {
        console.log('new global chat created -', data);
        dispatch(fetchChatInfo({ chatId: data.new_chat_id, ownerId: userData?.id! }));
      },

      document_create: async (data: any) => {
        const response = await api.getDocumentById(data.document_id);
        dispatch(fetchStatusDocuments({ type: 'replace', newDocuments: [response.data] }));
        console.log('Get session message:', data);
      },

      signing_update: async (data: any) => {
        const response = await api.getDocumentById(data.document_id);
        dispatch(fetchStatusDocuments({ type: 'replace', newDocuments: [response.data] }));
        console.log('Get session message:', data);
      },

      folder_create: async (data: any) => {
        const response = await api.getFolderOfFolder(data.folder_id, data.room_id);
        console.log('Get session message:', data);
        dispatch(
          fetchStatusDocuments({
            type: 'replace',
            newFolder: response.data,
            parent_folder_id: data.parent_folder_id,
          })
        );
      },

      document_delete: async (data: any) => {
        console.log('Document deleted:', data);
        dispatch(fetchDeleteDocument(data));
      },

      folder_delete: async (data: any) => {
        console.log('Folder deleted:', data);
        dispatch(fetchDeleteFolder(data));
      },

      document_move: async (data: any) => {
        console.log('Document moved:', data);
        dispatch(fetchDocumentMove(data));
      },

      document_update: async (data: any) => {
        console.log('Document updated:', data);
        dispatch(fetchDocumentUpdate(data));
      },

      document_replace: async (data: any) => {
        console.log('Document version updated:', data);
        const parsedMessage = data.message && JSON.parse(data.message)?.text;
        const prev_version = parsedMessage && JSON.parse(parsedMessage)?.prev_version;
        dispatch(fetchDocumentUpdate({ ...data, prev_version }));
      },

      permissions_update: async (data: any) => {
        console.log('Document updated:', data);
        dispatch(fetchFoldersPermissionsUpdate(data));
      },

      notification_create: async (message: any) => {
        console.log('Notification created:', message);
        dispatch(fetchRoomNotifications({ locale }));
      },

      rooms_update: async (data: any) => {
        console.log('Message:', data);
        dispatch(updateRooms());
      },

      message_cancel: async (data: any) => {
        console.log('Rejected messages', data);
        const action = (await dispatch(setRejectMessages({ type: data.type }))) as any;
        action.payload.isShowSupport &&
          message.warning(
            <span
              dangerouslySetInnerHTML={{
                __html: t('Chats.rejectedMessages.support'),
              }}
            />,
            7
          );
      },

      message_view: (data: any) => {
        console.log('Get viewing message:', data);
        dispatch(switchChatMessagesStatus(data.message));
      },

      successful_payment: async (data: any) => {
        console.log('Payment info:', data);
        dispatch(setIsOpenSuccessfulPayment(true));
      },

      chat: async (data: any) => {
        if (data.email !== userData?.email) {
          dispatch(fetchUnviewedAmount({ data, count: 1 }));
        }

        dispatch(fetchChatData({ data, type: 'replace' }));
        console.log('Get session message:', data);
      },

      members_update: async (data: any) => {
        console.log('Change invite status info:', data);
        dispatch(fetchUsersOnSokcet(data.message));
        // dispatch(setIsOpenSuccessfulPayment(true));
      },

      init: (data: any) => {
        console.log('init -', data);
      },

      logout: (data: any) => {
        deleteCookie('athena-token');
        deleteCookie('refresh_token');
        clearRedux();
        navigate('/login');
        setCookie('sid', '', 1);
      },

      disconnect: () => {
        setIsPausedWS(true);
        console.log(`The session is closed at: ${new Date().toLocaleString()}`);
      },
    };
  }, [locale, userData]);

  React.useEffect(() => {
    if (!isPausedWS) {
      const userCheckingMessages = localStorage.getItem(userData?.email!);
      const checkingMessagesCount = userCheckingMessages ? JSON.parse(userCheckingMessages).length : 0;
      // @ts-ignore
      workerRef.current = new SharedWorker(new URL('../../utils/Worker.ts', import.meta.url));
      const token = getCookie('athena-token');
      workerRef.current.port.start();
      workerRef.current.port.postMessage({
        event: 'connect',
        url: socketUrl,
        checkingMessagesCount,
        userId: userData?.id,
        token,
      });
      workerRef.current.port.onmessage = (message: any) => {
        if (message.data.event === 'connect') {
          setCookie('sid', message.data.sid || '', 1);
          dispatch(setIsLoadedSession(true));
        } else {
          (workerMessageListeners[message.data?.event as keyof typeof workerMessageListeners] || function () {})(
            message.data
          );
        }
      };

      window.addEventListener('beforeunload', () => {
        workerRef.current?.port.postMessage({ event: 'close' });
        dispatch(setMessagesToLocalStorage());
      });
    }

    return () => {
      if (!getCookie('athena-token')) workerRef.current?.port.postMessage({ event: 'logout' });
      if (isLoadedSession) workerRef.current?.port.close();
    };
  }, [isPausedWS]);

  React.useEffect(() => {
    if (dataRoom?.id && isLoadedSession) {
      workerRef?.current!.port.postMessage({ event: 'init', roomId: dataRoom?.id });
    }
  }, [dataRoom?.id, isLoadedSession]);

  React.useMemo(
    () =>
      document.addEventListener('click', function () {
        if (isLoadedSession) {
          setIsPausedWS(false);
        }
      }),
    [isLoadedSession, isPausedWS]
  );

  React.useEffect(() => {
    const data = {
      room_id: dataRoom?.id,
      message: chatMessage.message,
      chat_id: selectedChat?.id,
      created_at: chatMessage.id,
      status: chatMessage.status,
      email: userData?.email!,
      user_name: `${userData?.first_name} ${userData?.last_name}`,
      timestamp: chatMessage.id,
      replied_to: chatMessage.replied_to ? chatMessage.replied_to.id : null,
    };

    chatCases[chatStatus](data);
  }, [chatStatus]);

  React.useEffect(() => {
    if (viewsMessagesIds.length) {
      workerRef.current!.port.postMessage({ event: 'message_view', message: { message_ids: viewsMessagesIds } });
    }
  }, [viewsMessagesIds]);

  React.useEffect(() => {
    if (!roomChatLoading) {
      workerRef.current?.port.postMessage({ event: 'check_messages_canceling' });
    }
  }, [roomChatLoading]);

  // Функционал прерывания сессии при неактивной вкладке браузера (сворачивание браузера/переход на другую вкладку)
  // React.useMemo(() => document.addEventListener('visibilitychange', function(){
  //   if (document.hidden && !isPausedWS && sessionChanel.current?.readyState === 1){
  //     setIsPausedWS(true);
  //     // console.log(`Сlosed: ${new Date().toLocaleString()}`);
  //     // sessionChanel.current?.send(`Сlosed: ${new Date().toLocaleString()}`);
  //     sessionChanel.current?.close();
  //   } else if (!document.hidden && isPausedWS) {
  //     setIsPausedWS(false);
  //   }
  // }), [sessionChanel.current?.readyState]);

  // Ручное прерыване канала вебсокета для тестирования
  // const closeWS = () => {
  //   sessionChanel.current?.close();
  //   // console.log(`Сlosed: ${new Date().toLocaleString()}`);
  //   // setIsPausedWS(true);
  // }
  // return (
  //   !isPausedWS
  //     ? <button style={{position: 'absolute'}} onClick={closeWS}>{ 'Остановить соединение' }</button>
  //     : null
  // );

  return null;
}
