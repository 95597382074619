import api from 'api';
import { IColumn, IDocument } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Empty, message, Table } from 'antd';
import TableLoader from 'components/Sceletons/TableLoader';
import { getStateColumns } from './columns/StateColumns';
import classes from './StateOfTerm.module.scss';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { useAppSelector } from 'store/hook';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';

type StateOfTermType = {
  selectedTerm?: IDocument;
};

const StateOfTerm = ({selectedTerm}: StateOfTermType) => {
  const [isLoadingState, setIsLoadingState] = React.useState(false);
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [dataSource, setDataSource] = React.useState<any[]>([]);

  const { userData } = useAppSelector((store) => store.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);

  const { t, i18n } = useTranslation();

  const getTermsState = async () => {
    setIsLoadingState(true);
    try {
      const response = selectedTerm
        ? await api.getStateOfTerm(selectedTerm?.id!, dataRoom?.id!)
        : await api.setRoomAgreementState(dataRoom?.id!);

      const stateOfStatuses = response.data.members_status || response.data.rooms_members_status;
      setDataSource(stateOfStatuses.map((user: any) => ({
        ...user,
        signed_date: user.accepted_at ? dayjs(user.accepted_at).valueOf() : null,
        status_access: user.status_access || user.user_in_room_status,
        key: user.id,
      })));
    } catch (e) {
      message.error(t('Settings.terms.errorUploadState'));
    } finally {
      setIsLoadingState(false);
    };
  };

  React.useEffect(() => {
    getTermsState();
  }, [selectedTerm]);

  React.useEffect(()=> {
    const stateColumns = getStateColumns(t);
    const customColumns = stateColumns.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[dataSource, i18n.language]);

  const getStatusRow = (status?: string | number) => {
    switch(status) {
      case 'denied':
        return <div className={classes.rowWrap}>
          <CloseOutlined className={classes.rejectIcon}/>
          <span className={classes.reject}>{t('Settings.terms.state.reject')}</span>
        </div>;
      case 'approved':
        return <div className={classes.rowWrap}>
          <CheckOutlined className={classes.acceptIcon}/>
          <span className={classes.accept}>{t('Settings.terms.state.accept')}</span>
        </div>;
      case 3:
        return <div className={classes.rowWrap}>
          <CloseOutlined className={classes.rejectIcon}/>
          <span className={classes.reject}>{t('Settings.terms.state.reject')}</span>
        </div>;
      case 2:
        return <div className={classes.rowWrap}>
          <CheckOutlined className={classes.acceptIcon}/>
          <span className={classes.accept}>{t('Settings.terms.state.accept')}</span>
        </div>;
      case 1: return <div className={classes.rowWrap}>
        <ClockCircleOutlined className={classes.notAcceptIcon}/>
        <span className={classes.nonAccept}>{t('Settings.terms.state.nonAccept')}</span>
      </div>
      default: 
        return <div className={classes.rowWrap}>
          <ClockCircleOutlined className={classes.notAcceptIcon}/>
          <span className={classes.nonAccept}>{t('Settings.terms.state.nonAccept')}</span>
        </div>;
    };
  };

  const renderCell = (props: any) => {
    const { value, info, col } = props;

    if(col.key === 'name'){      
      return  `${value} - ${info.email}`;
    };

    if(col.key === 'signed_date'){
      return value ? getDateWithTimeZone(value, userData?.date_format!) : '-';
    };

    if(col.key === 'status_access'){
      return getStatusRow(value)
    };

    return value;
  };

  if (isLoadingState) {   
    return (
      <div
        className={classes.tabWrap}
        style={{height: 'calc(100vh - 256px)', padding: 0}}
      >
        <TableLoader height={'calc(100vh - 212px)'}/>
      </div>
    )
  };

  return (
    <div
      className={classes.tabWrap}
      style={{ height: 'calc(100vh - 256px)', padding: 15 }}
    >
      <Table
        style={{backgroundColor: 'white'}}
        dataSource={dataSource}
        columns={modifiedColumns}
        pagination={false}
        scroll={{ y: 'calc(100vh - 335px)' }}
        size='middle'
        locale={{
          emptyText: <Empty
            style={{ height: 'calc(100vh - 395px)', paddingTop: 100 }}
            description={t('Settings.terms.state.emptyTable')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        }}
      />
    </div>
  );
}

export default StateOfTerm;
