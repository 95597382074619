import { Tooltip, Typography, TypographyProps } from 'antd';
import { LinkProps } from 'antd/es/typography/Link';
import { Fragment } from 'react';

const getLinkedTextArray = (text: string) => {
  const linkedElements: JSX.Element[] = [];

  text.replace(
    /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
    (original, link, text) => {
      linkedElements.push(
        link ? (
          <Typography.Link
            style={{ color: '#ccc' }}
            target='_blank'
            href={(link[0] === 'w' ? '//' : '') + link}
            key={linkedElements.length}
          >
            {link}
          </Typography.Link>
        ) : (
          text
        )
      );

      return original;
    }
  );

  return linkedElements;
};

export const parsedChatMessages = (text: string, props: LinkProps, title?: string) => {
  const linkedMessages = getLinkedTextArray(text);

  const parsedTagsMessages = linkedMessages.map((element, index) => {
    if (typeof element !== 'string') return element;

    // @ts-ignore
    const tags = Array.from((element as string).matchAll(/\[link\](.+)\[\/link\]/gm)?.map((match) => match[1]));
    if (!tags.length) return element;

    const splited = (element as string).split(/\[link\].+\[\/link\]/);
    const parsedContent = (
      <Fragment key={`parsedTags-${index}`}>
        {splited.map((split, idx) => (
          <Fragment key={`tag-${tags[idx]}-${idx}`}>
            {split}
            {idx < splited.length - 1 ? (
              <Tooltip title={title} trigger='hover'>
                <Typography.Link {...props}>{(tags as string[])[idx]}</Typography.Link>
              </Tooltip>
            ) : null}
          </Fragment>
        ))}
      </Fragment>
    );

    return parsedContent;
  });

  return parsedTagsMessages;
};

const replacePartWithJSX = (text: string, jsxPart: JSX.Element, targetWord: string) => {
  const parts = text.split(targetWord);
  return (
    <>
      {parts[0]}
      {jsxPart}
      {parts[1]}
    </>
  );
};

export default getLinkedTextArray;
