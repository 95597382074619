import React from 'react';
import classes from '../AccessTerms.module.scss';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { IDocument } from 'interfaces';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined, DeleteOutlined, EyeOutlined, SignatureFilled } from '@ant-design/icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import api from 'api';
import { useAppSelector } from 'store/hook';


type ActiveTermTypes = {
  document: IDocument;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTerm: React.Dispatch<React.SetStateAction<IDocument | null>>;
  setActiveTerms: React.Dispatch<React.SetStateAction<IDocument[]>>;
  setMaxFileCount: React.Dispatch<React.SetStateAction<number>>;
}

const ActiveTerm = ({document, setIsOpen, setSelectedTerm, setActiveTerms, setMaxFileCount}: ActiveTermTypes) => {
  const [isDeletingTerm, setIsDeletingTerm] = React.useState(false);

  const { userPermissions } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const { t } = useTranslation();

  const formatNDA = document.name?.split('.')?.reverse()[0];  
  const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';
  const canEdit = userPermissions?.can_edit_room_settings;

  const openTermInModal = () => {
    setIsOpen(true);
    setSelectedTerm(document);
  }

  const deleteTerm = async () => {
    setIsDeletingTerm(true);
    try {
      await api.deleteTermOfAccess(document.id, dataRoom?.id!);
      setActiveTerms(prev => prev.filter(el => el.id !== document.id));
      setMaxFileCount(prev => prev + 1);
      message.success(t('Settings.terms.successDeleted'));
    } catch (e) {
      message.error(t('Settings.terms.deleteError'));
    } finally {
      setIsDeletingTerm(false);
    }
  }
  
  const getStatusRow = (status?: string) => {
    switch(status) {
      case 'denied':
        return <div className={classes.statusTermWrap}>
          <CloseOutlined className={classes.rejectIcon}/>
          <span className={classes.reject}>{t('Settings.terms.state.rejected')}</span>
        </div>;
      case 'approved':
        return <div className={classes.statusTermWrap}>
          <CheckOutlined className={classes.acceptIcon}/>
          <span className={classes.accept}>{t('Settings.terms.state.accepted')}</span>
        </div>;
      default: 
        return <div className={classes.statusTermWrap}>
          <ClockCircleOutlined className={classes.notAcceptIcon}/>
          <span className={classes.nonAccept}>{t('Settings.terms.state.nonAccepted')}</span>
        </div>;
    };
  };

  return (
    <div className={cn(classes.uploadedWrap, canEdit ? classes.canEdit : classes.cantEdit)}>
      <div onClick={openTermInModal} className={classes.cardNDA}>
        <div style={{display: 'flex', alignItems:'center'}}>
          <div style={{ fontSize: 32 }}>{documnentIcons[icon as keyof typeof documnentIcons]}</div>
          <div style={{ paddingLeft: 16 }}>{document?.name}</div>
        </div>

        <div
          onClick={openTermInModal}
          className={classes.cardView}
        >
          {document.status === 0 ? <SignatureFilled /> : <EyeOutlined />}
        </div>

      </div>

      {getStatusRow(document.user_status?.status_access)}

      {canEdit && <div className={classes.deleteButton}>
        <Button
          disabled={dataRoom?.access_terms_status == 'waiting_for_approve'}
          loading={isDeletingTerm}
          onClick={deleteTerm}
          icon={
            <DeleteOutlined
            className={classes.deleteTermIcon}
          />}
        />
      </div>}

    </div>
  );
}

export default ActiveTerm;
