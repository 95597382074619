import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { deleteCookie } from 'helpers/cookies';
import { IUserData } from 'interfaces';
import { RootState } from 'store';
import { setUserAvailableRooms } from 'store/slices/userDataSlice';

export const prepareRooms = (response: IUserData) => {
  return response.available_rooms
    .map((room) => ({
      ...room,
      created_at: Date.parse(String(room.created_at)),
      last_action_timestamp: Date.parse(String(room.last_action_timestamp || room.created_at)),
    }))
    .sort((a, b) => b.last_action_timestamp - a.last_action_timestamp);
};

export const fetchUserData = createAsyncThunk('dataUser/fetchAll', async (props: {}, thunkAPI) => {
  try {
    const userInfo = await api.getUserInfo();
    return {
      ...userInfo.data,
      available_rooms: prepareRooms(userInfo.data),
    };
  } catch (e) {
    deleteCookie('athena-token');
    deleteCookie('refresh_token');
    window.location.href = `/login`;
    return thunkAPI.rejectWithValue('Error while retrieving user data');
  }
});

export const fetchUserPermissions = createAsyncThunk(
  'permissionsUser/fetchAll',
  async (props: { idRoom: string }, thunkAPI) => {
    try {
      const userPermissions = await api.getUserPermissions(props.idRoom);
      return userPermissions.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);

export const updateRooms = createAsyncThunk('update/rooms', async (payload, thunkAPI) => {
  try {
    const { dispatch } = thunkAPI;
    const userInfo = await api.getUserInfo();
    const { notifications } = (thunkAPI.getState() as RootState).dataRoom;
    const notApprovedRooms = notifications.filter(({ details }) => details.invite_status === 0)
    .map((invites) => ({
      id: invites.details.room_id,
      name: invites.details.room_name,
      description: '',
      created_at: Date.now(),
      last_action_timestamp: '',
      owner_id: '',
      folders: [],
      watermark_enabled: false,
      invite_id: invites.details.invite_id
    })); 
    
    const available_rooms = prepareRooms(userInfo.data);
    dispatch(setUserAvailableRooms([...notApprovedRooms, ...available_rooms]));
  } catch (err) {
    console.log(err);
  }
});
