import { BellOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Badge, Empty, Tabs, TabsProps } from 'antd';
import classNames from 'classnames';
import RowLoader from 'components/Sceletons/RowLoader';
import { IDataRoom, IUserData } from 'interfaces';
import { NotificationTypeEnum } from 'interfaces/INotification';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchRoomNotifications } from 'store/reducers/roomsCreator';
import classes from './HeaderComponent.module.scss';
import NotificationList from './NotificationList';
import { setUserData } from 'store/slices/userDataSlice';

const UserLogs = ['invite', 'update_user_permissions', 'delete_room', 'new_message'];

// Массив с действиями, связанными с соглашениями (agreements), без signings
const agreements: NotificationTypeEnum[] = [
  NotificationTypeEnum.AGREEMENT_INITIATED,
  NotificationTypeEnum.DECLINE_AGREEMENT,
  NotificationTypeEnum.DELETE_AGREEMENT,
  NotificationTypeEnum.INVITE,
  NotificationTypeEnum.NDA_ENABLE,
  NotificationTypeEnum.UPDATE_USER_PERMISSIONS,
];

// Массив с действиями, связанными с подписанием (signings)
const signings: NotificationTypeEnum[] = [
  NotificationTypeEnum.INITIATION_OF_SIGNING,
  NotificationTypeEnum.FINALIZATION_OF_SIGNATURE,
  NotificationTypeEnum.CANCELING_THE_SIGNING,
  NotificationTypeEnum.REJECTION_SIGNING,
  NotificationTypeEnum.SIGNING_OF_DOCUMENT,
];

export const hasRoom = (roomList: IDataRoom[] | undefined, room_id: string) =>
  Boolean(roomList?.find(({ id }) => room_id === id));

type Props = {};

const Notifications: React.FC<Props> = () => {
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
  const [tabItems, setTabItems] = React.useState<TabsProps['items']>([]);
  const [selectedKeyIdx, setSelectedKeyIdx] = React.useState(0);

  const { notifications, notificationStatus, dataRoom, isLoadingNotifications } = useAppSelector(
    (state) => state.dataRoom
  );
  const { userData, userAndRoomStatus } = useAppSelector((state) => state.userData);

  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  const locale = i18n.language || 'en';

  const notificationRef = React.useRef<HTMLDivElement>(null);
  const notificationSignalRef = React.useRef<() => void>();

  useEffect(() => {
    if (notifications.length) {
      const itemsFilters = [
        {
          key: 'all',
          children: notifications,
          isDisabled: false,
        },
        {
          key: 'currentRoom',
          children: notifications.filter(({ details }) => details.room_id === dataRoom?.id),
          isDisabled: !dataRoom?.id,
        },
        {
          key: 'documents',
          children: notifications.filter(({ type }) => !UserLogs.includes(type)),
          isDisabled: !notifications.filter(({ type }) => !UserLogs.includes(type)).length,
        },
        {
          key: 'invites',
          children: notifications.filter(({ type }) => type === 'invite'),
          isDisabled: !notifications.filter(({ type }) => type === 'invite').length,
        },
        {
          key: 'agreements',
          children: notifications.filter(({ type }) => agreements.includes(type)),
          isDisabled: !notifications.filter(({ type }) => agreements.includes(type)).length,
        },
        {
          key: 'signings',
          children: notifications.filter(({ type }) => signings.includes(type)),
          isDisabled: !notifications.filter(({ type }) => signings.includes(type)).length,
        },
        
      ];

      const items: TabsProps['items'] = itemsFilters.map((item) => ({
        ...item,
        disabled: item.isDisabled,
        label: t(`Header.Notification.tabs.${item.key}`),
        children: (
          <NotificationList
            notifications={item.children}
            setIsNotificationsOpen={setIsNotificationsOpen}
            isLoadingNotifications={isLoadingNotifications}
          />
        ),
      }));

      setTabItems(items);
    }
  }, [notifications, dataRoom, isLoadingNotifications]);

  const fetchNotifications = async (locale: string) => {
    const dispatchPromise = dispatch(fetchRoomNotifications({ locale }));
    notificationSignalRef.current = dispatchPromise.abort;
    await dispatchPromise;
  };

  React.useEffect(() => {
    const onOverLayoutClick = (event: globalThis.MouseEvent) => {
      const withinBoundaries = event.composedPath().includes(notificationRef.current!);

      if (!withinBoundaries) {
        setIsNotificationsOpen(false);
        // notificationSignalRef.current!();
      }
    };
    if (notificationRef.current) {
      document.addEventListener('click', onOverLayoutClick);
    }

    return () => {
      notificationSignalRef.current && notificationSignalRef.current();
      document.removeEventListener('click', onOverLayoutClick);
    };
  }, [notificationRef]);

  React.useEffect(() => {
    if (userAndRoomStatus !== 'pendingUserData') {
      fetchNotifications(locale);
    }
  }, [locale, userAndRoomStatus]);


  const onNotificationOpen = () => {
    // notificationSignalRef.current && notificationSignalRef.current();
    setIsNotificationsOpen((prev) => !prev);
  };

  const newNotificationsCount = React.useMemo(
    () => notifications.filter((not) => not.status === 0).length,
    [notifications]
  );
  const showNotificationsCount = newNotificationsCount === 0 || isLoadingNotifications || isNotificationsOpen;

  const onChange = (activeKey: string) => setSelectedKeyIdx(tabItems?.findIndex((tab) => tab.key === activeKey)!);
  const onLeftClick = () => setSelectedKeyIdx((prev) => (prev !== 0 ? prev - 1 : 0));
  const onRightClick = () =>
    setSelectedKeyIdx((prev) => (prev !== tabItems?.length! - 1 ? prev + 1 : tabItems?.length! - 1));

  const notificationsCases = {
    pending: [1, 2, 3, 4].map((_, idx) => (
      <RowLoader padding={'0 0 0 0'} width={350} height={90} key={`load-${idx}`} />
    )),
    fulfilled: (
      <Tabs
        items={tabItems}
        activeKey={tabItems?.length ? tabItems[selectedKeyIdx].key : undefined}
        onChange={onChange}
        tabBarExtraContent={{
          left: <LeftOutlined onClick={onLeftClick} className={classes.leftIcon} />,
          right: <RightOutlined onClick={onRightClick} className={classes.rightIcon} />,
        }}
        more={{ icon: null, visible: false }}
      />
    ),
    empty: <Empty description={t('Header.Notification.empty')} />,
  };

  return (
    <>
      <div className={classes.notification} ref={notificationRef}>
        <div className={classes.notificationWrap} onClick={onNotificationOpen}>
          <Badge
            dot={Boolean(newNotificationsCount)}
            count={!showNotificationsCount ? newNotificationsCount : undefined}
            overflowCount={99}
          >
            <BellOutlined className={classes.notificationBell} />
          </Badge>
        </div>

        <div className={classNames(classes.notification_container, isNotificationsOpen && classes.notification_open)}>
          {notificationsCases[notificationStatus]}
        </div>
      </div>
    </>
  );
};

export default Notifications;
