import { ClockCircleOutlined, LockFilled, LockOutlined, MoreOutlined } from '@ant-design/icons';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Dropdown, MenuProps, Tooltip } from 'antd';
import cn from 'classnames';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import systemPages from 'helpers/pagesAndLocation';
import { dateFormatType, IDataRoom } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hook';
import classes from './RoomsTab.module.scss';

type RoomCardProps = {
  room: IDataRoom;
  onOpenClick: (_: any, room: IDataRoom, isLoadingRoom: boolean) => Promise<void>;
  controlItems: (room: IDataRoom) => MenuProps['items'];
  toRoomSettings: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, room: IDataRoom) => void;
  searchValue?: string;
  markText?: (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => void;
  dateFormat: dateFormatType;
};

const RoomCard = ({ room, onOpenClick, controlItems, toRoomSettings, searchValue, markText, dateFormat }: RoomCardProps) => {
  const { isLoadingUserPermissions, userData } = useAppSelector((store) => store.userData);
  const { isLoadingRooms } = useAppSelector((store) => store.dataRoom);
  const { selectedKeys } = useAppSelector((store) => store.windowState);  
  
  const { name, last_action_timestamp, agreement_enable, created_at, invited_at } = room;
  const { t } = useTranslation();
    
  const isLoadingRoom = isLoadingRooms || isLoadingUserPermissions;
  const params = useParams();
  const markerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (markText) {
      const text = searchValue || '';
      markText(text, markerRef);
    }
  }, [searchValue]);

  const privatedRooms = ['143d9fd4-96b7-46e8-819f-9515a04eaff9'] // не трогать
  const isCurrentRoom = params.room === room.id;

  return (
    <div
      className={cn(
        classes.room,
        isCurrentRoom ? classes.activeRoom : 'null',
        systemPages.includes(selectedKeys[0]) ? classes.cursor : 'null',
        isLoadingRoom ? classes.loadingRoom : 'null',
        ((room?.tariff?.tariff_name && room.tariff_lifetime_to === null) && !isCurrentRoom) && classes.awaiting,
        room.access_terms_status == 'waiting_for_approve' && !isCurrentRoom && classes.awaiting,
      )}
      id={`room_card_${room.id}`}
      onClick={(e) => onOpenClick(e, room, isLoadingRoom)}
    >
      <div className={classes.roomContent}>
        <div className={classes.jusctifySpace}>
          <div ref={markerRef} className={classes.roomTitle}>
            {agreement_enable &&
              <Tooltip placement='topLeft' title={t('Rooms.NDARoom')}>
                {isCurrentRoom ? <LockFilled className={classes.iconNDA}/> : <LockOutlined className={classes.iconNDA}/>}
              </Tooltip>
            }
            {room?.tariff?.tariff_name && room.tariff_lifetime_to === null &&
              <Tooltip placement='topLeft' title={t('Rooms.notPaid')}>
                <ClockCircleOutlined className={classes.iconClock}/>
              </Tooltip>
            }
            {room?.access_terms_status == 'waiting_for_approve' &&
              <Tooltip placement='topLeft' title={t('Rooms.notAccessTerms')}>
                <ClockCircleOutlined className={classes.iconClock}/>
              </Tooltip>
            }
            <Tooltip placement='topLeft' mouseEnterDelay={1} title={name}>
              <span className={classes.nameOverflow}>{name}</span>
            </Tooltip>
          </div>

          <div className={classes.menuActions}>
            {room?.invite_id ? (
              <span className={classes.new}>{t('Rooms.new')}</span>
            ): null}
            {userData?.id === room.owner_id ? (
              <Tooltip placement='top' title={t('Rooms.owner')}>
               <FontAwesomeIcon className={classes.info} color={'gold'} icon={faCrown} />
              </Tooltip>
            ) : null}
            <Dropdown menu={{ items: controlItems(room) }} placement='topLeft'>
              <MoreOutlined onClick={(e) => e.stopPropagation()} className={classes.iconBtn} rotate={90} />
            </Dropdown>
          </div>
        </div>

        <div className={classes.jusctifySpace}>
          <div className={classes.roomDescription}>{
            last_action_timestamp || created_at 
              ? getDateWithTimeZone(last_action_timestamp || created_at, dateFormat)
              : invited_at
                ? `${t('Rooms.invitedAt')} ${getDateWithTimeZone(invited_at, dateFormat)}`
                : '-'
          }</div>

          {!privatedRooms.includes(room.id) &&
            <div className={classes.badges}>
              {/* BUGFIX/9995 */}
              {/* {room.new_count ? 
                <Tooltip placement='topLeft' mouseEnterDelay={1} title={t('Rooms.badges.new_files')}>
                  <Badge size='small' count={room.new_count} color="#4f89f7" />
                </Tooltip> : null
              } */}
              {room.unviewed_amount ? 
                <Tooltip placement='topLeft' mouseEnterDelay={1} title={t('Rooms.badges.new_messages')}>
                  <Badge size='small' count={room.unviewed_amount} />
                </Tooltip> : null
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
