import { Modal } from 'antd';
import { RcFile } from 'antd/es/upload';
import prepareDocuments from 'helpers/prepareDocuments';
import { TFunction } from 'i18next';
import { IDocument } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsUploadingDocuments } from 'store/slices/windowStateSlice';
import classes from './Postfix.module.scss';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  isOpen: boolean;
  duplicateDocuments: {
    forUpload: RcFile[];
    forDelete: IDocument[];
    generatedNames: string[];
  };
  deleteChoosenDocuments: (documents: any[], documentList: IDocument[]) => void;
  handleUpload: (fileList?: RcFile[]) => void;
  onCancel: () => void;
  t: TFunction;
  setIsUploadModalOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PostfixModal({
  isOpen,
  duplicateDocuments,
  t,
  onCancel,
  handleUpload,
  deleteChoosenDocuments,
  setIsUploadModalOpen,
}: Props) {
  const dispatch = useAppDispatch();
  const { isUploadingDocuments } = useAppSelector((state) => state.windowState);

  const onUploadClick = async () => {
    dispatch(setIsUploadingDocuments(true));
    await handleUpload(
      duplicateDocuments.forUpload.map((file, idx) => {
        if (duplicateDocuments.generatedNames[idx]) {
          return new File([file], duplicateDocuments.generatedNames[idx], { type: file.type }) as RcFile;
        }

        return file;
      })
    );
    onCancel();
    setIsUploadModalOpen(false);
    dispatch(setIsUploadingDocuments(false));
  };

  const onReplaceClick = async () => {
    dispatch(setIsUploadingDocuments(true));
    const preparedDocumentKeys = prepareDocuments(duplicateDocuments.forDelete, []).map(({ key }) => ({ key }));
    await deleteChoosenDocuments(preparedDocumentKeys, duplicateDocuments.forDelete);
    await handleUpload(duplicateDocuments.forUpload);
    onCancel();
    dispatch(setIsUploadingDocuments(false));
    setIsUploadModalOpen(false);
  };

  const handleCancelAndClose = (event: any) => {
    if (event.currentTarget.id === 'cancelButton') {
      onReplaceClick();
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        title={t('Documents.modals.postfix.title')}
        onCancel={handleCancelAndClose}
        open={isOpen}
        cancelButtonProps={{ id: 'cancelButton' }}
        cancelText={t('Documents.modals.postfix.replaceButton')}
        okText={t('Documents.modals.postfix.uploadButton')}
        onOk={onUploadClick}
        onClose={onCancel}
        classNames={{ body: classes.body, footer: classes.footer }}
      >
        <div>{t('Documents.modals.postfix.body')} {duplicateDocuments.forDelete.map(({ name }) => name).join(', ')}</div>
        <div style={{marginTop: 10}}>{t('Documents.modals.postfix.otherFiles')}</div>
        {isUploadingDocuments && <UploadingComponent />}
      </Modal>
    </>
  );
}
