import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { IDataRoom, IDocument, IUserData } from 'interfaces';
import dayjs from 'dayjs';
import axios from 'axios';
import { RootState } from 'store';
import { setUserData } from 'store/slices/userDataSlice';
import { setNotificationsStatus } from 'store/slices/dataRoomSlice';

export const fetchRoom = createAsyncThunk('dataRoom/fetchAll', async (props: { id: string }, thunkAPI) => {
  try {
    const rooms = await api.getRoom(props.id)
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const inSeconds = dayjs(rooms.data.user_status_change_at).valueOf();
    const rightTime = rooms.data.user_status_change_at ? dayjs(inSeconds - offset).format('DD.MM.YYYY, HH:mm') : '-';

    return {
      ...rooms.data,
      description: rooms.data.description ? rooms.data.description : '',
      user_status_change_at: rightTime
    };
  } catch (e) {
    return thunkAPI.rejectWithValue('Download failed');
  }
});

export const fetchConfidentialDocuments = createAsyncThunk(
  'confidentialDocuments/fetchAll',
  async (props: { room_id: string }, thunkAPI) => {
    try {
      const documents = await api.getConfidentialDocuments(props.room_id);
      const offset = new Date().getTimezoneOffset() * 60 * 1000;

      const preparedDocuments = documents.data.map((document: IDocument) => {
        const inSeconds = dayjs(document.upload_at).valueOf();
        const rightTime = document.upload_at ? dayjs(inSeconds - offset).format('DD.MM.YYYY, HH:mm:ss') : '-';
        return {
          ...document,
          upload_at: rightTime,
          key: document.token,
        };
      });
      return preparedDocuments;
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);

export const fetchRoomNotifications = createAsyncThunk(
  'notifications/fetchAll',
  async (payload: { locale: string }, thunkAPI) => {
    try {
      const { userData } = (thunkAPI.getState() as RootState).userData;
      const { dispatch } = thunkAPI;
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => source.cancel());
      const response = await api.getNotifications(source.token, payload.locale);
      const currentRoomIds = userData?.available_rooms.map(room => room.id) 

      const netApprovedRooms: IDataRoom[] = response.data
        .filter(({ details }) => details.invite_status === 0)
        .filter(({ details }) => !currentRoomIds?.includes(details.room_id))
        .map((invites) => ({
          id: invites.details.room_id,
          name: invites.details.room_name,
          description: '',
          created_at: 0,
          invited_at: invites.created_at,
          last_action_timestamp: '',
          owner_id: '',
          folders: [],
          watermark_enabled: false,
          invite_id: invites.details.invite_id,
        }));

      const newUserData: IUserData = {
        ...userData!,
        available_rooms: [...netApprovedRooms, ...userData?.available_rooms!],
      };
      dispatch(setUserData(newUserData));
      setTimeout(() => {
        dispatch(setNotificationsStatus(response.data.length ? 'fulfilled' : 'empty'));
      }, 10)
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
