import { ILogs } from 'interfaces';
import dayjs from 'dayjs'

const prepareLogs = (logs: ILogs[]) => {
  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const preparedLogs = logs.map((log: ILogs, i: number) => {
    const inSeconds = dayjs(log.timestamp, 'DD.MM.YYYY, hh:mm:ss').valueOf();
    const rightTime = dayjs(inSeconds - offset);
    
    return {
      key: log.timestamp + i,
      username: log.user?.name || '-',
      email: log.user?.email || '-',
      action: log.name,
      entityname: log.entity?.name || 'отсутствует',
      date: rightTime.format('DD.MM.YYYY'),
      time: rightTime.format('HH:mm:ss'),
      timestamp: rightTime,
      details: log.details || null,
      size: log.details?.document_size ? log.details.document_size : '',
      type: log.type,
    }}
  )

  return preparedLogs
}

export default prepareLogs;
