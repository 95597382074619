import { Modal } from 'antd';
import { ReactNode } from 'react';
import classes from './ConfirmModal.module.scss';

type Props = {
  title?: string;
  loading: boolean;
  open: boolean;
  bodyContent: ReactNode;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
  onCancel: () => void;
  onClose: () => void;
};

export default function ConfirmModal({ bodyContent, onCancel, onClose, ...props }: Props) {
  const handleCancelAndClose = (event: any) => {
    if (event.currentTarget.id === 'cancelButton') {
      onCancel();
    } else {
      onClose();
    }
  };

  return (
    <Modal
      {...props}
      className={classes.modal}
      cancelButtonProps={{ id: 'cancelButton' }}
      classNames={{ body: classes.body }}
      confirmLoading={props.loading}
      onCancel={handleCancelAndClose}
    >
      {bodyContent}
    </Modal>
  );
}
