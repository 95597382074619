import axios, { AxiosError, AxiosResponse, CancelToken } from 'axios';
import blobErrorToObject from 'helpers/blobErrorToObject';
import { ISignUpData, ILoginData } from 'interfaces';

type SignResponseType = {
  user: ISignUpData;
  accessToken: string;
};

type LoginResponseType = {
  access_token: string;
  refresh_token: string;
  message?: string;
};

type InviteSignupResponse = {
  email: string;
  room_id: null | string;
  group_id: null | string;
  type: 'common';
};

// type QrResponse = {
//   access_token: string
// }

type AcceptResponse = {
  access_token: string;
  refresh_token: string
}

type ResetResponse = {
  success: boolean,
  message: string,
}

const instance = axios.create({
  // @ts-ignore
  baseURL: window.REACT_APP_SERVER_API !== 'REPLACE_REACT_APP_SERVER_API' ? window.REACT_APP_SERVER_API : process.env.REACT_APP_SERVER_API || 'http://localhost:8000/api',
  headers: { 'Access-Control-Allow-Origin': '*' },
  // withCredentials: true,
});

instance.interceptors.response.use(( response: AxiosResponse ) => {
  return response
}, async (error: AxiosError<any>) => {
  if (error?.response?.data.constructor.name === 'Blob'){
    error.response.data = await blobErrorToObject(error?.response?.data)
  };
  return Promise.reject(error)
});

// const qrResponse = (payload: string, signal: AbortSignal) => instance.get<QrResponse>(`/qr/check/?qr_data=${payload}`, { signal });

const login = (payload: FormData) => instance.post<LoginResponseType>('/users/login/?action=create', payload);
const signup = (payload: ISignUpData) => instance.post<SignResponseType>('/users/register/?action=create', payload);
const inviteSignup = (payload: string) => instance.get<InviteSignupResponse>(`/invites/invite/data/?jwt_token=${payload}&action=view`);
const acceptRegistration = (browser_data: string, user_id: string, signal: AbortSignal) => instance.post<AcceptResponse>(`/users/register/accept/?user_id=${user_id}&browser_data=${browser_data}`, { browser_data },  { signal }); 
const sendEmailToRestore = (payload: { email: string }) => instance.post<ResetResponse>('/users/send-restore/', payload); 
const restorePassword = (payload: { user_id: string, password: string }) => instance.post<ResetResponse>('/users/restore/', payload);
const refreshTokens = (payload: {refresh_token: string}) => instance.post<LoginResponseType>('/users/refresh-token/', payload);
const decodeDataFromLink = (jwt_token: string) => instance.get<any>(`/organizations/form/data/?jwt_token=${jwt_token}`);
const sendMessageToSupport = (payload: FormData) => instance.post<any>(`/support/`, payload);
const verifyAuth = (
  payload: { email: string; auth_password: string; browser_data: string },
  formData: FormData,
  token?: CancelToken
) =>
  instance.post<LoginResponseType>(
    `/users/login/verify/?email=${payload.email}&auth_password=${payload.auth_password}`,
    formData,
    { cancelToken: token }
  );

const auth = {
  login,
  signup,
  inviteSignup,
  acceptRegistration,
  sendEmailToRestore,
  restorePassword,
  refreshTokens,
  decodeDataFromLink,
  sendMessageToSupport,
  verifyAuth,
};

export default auth;
