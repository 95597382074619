import React from 'react';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined, DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Card, message, Upload, UploadFile } from 'antd';
import classes from './RoomAccess.module.scss';
import { ItemRender, RcFile } from 'antd/es/upload/interface';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { IDataRoom } from 'interfaces';
import { useTranslation } from 'react-i18next';
import api from 'api';
import dayjs from 'dayjs';
import { setRooms } from 'store/slices/userDataSlice';
import { fetchRoom } from 'store/reducers/roomsCreator';
import cn from 'classnames';
import { ConfirmationModal } from 'components/Modals';

const { Dragger } = Upload;

type RoomAccesseType = {
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};


const RoomAccess = ({setIsOpen}: RoomAccesseType) => {
  const [isUpdateLoading, setIsUpdateLoading] = React.useState<boolean>(false);
  const [NDADocument, setNDADocument] = React.useState<UploadFile[] | undefined>(undefined);
  const [confirmUploadingModal, setConfirmUploadingModal] = React.useState(false);

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData, userPermissions} = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const canEdit = userPermissions?.can_edit_room_settings;
  const formatNDA = dataRoom?.agreement_file?.split('.')?.reverse()[0];  
  const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';

  React.useEffect(() => {
    setNDADocument(dataRoom?.agreement_enable
      ? [{ name: dataRoom?.agreement_file, uid: dataRoom?.agreement_id, type: 'document' }]
      : undefined
    )
  }, [dataRoom]);

  const onBeforeUpload = (file: UploadFile) => {
    setNDADocument([file]);
    return false;
  };

  const onRemove = () => setNDADocument([]);
  const itemRender: ItemRender = React.useMemo(
    () =>
      (originNode, doc, fileList, { remove }) => (
        <div className={classes.file}>
          {originNode} <DeleteOutlined className={classes.deleteIcon} onClick={remove} />
        </div>
      ),
    []
  );

  const getStatusRow = (status?: number) => {
    switch(status) {
      case 3:
        return <div className={classes.statusTermWrap}>
          <CloseOutlined className={classes.rejectIcon}/>
          <span className={classes.reject}>{t('Settings.terms.state.rejected')}</span>
        </div>;
      case 2:
        return <div className={classes.statusTermWrap}>
          <CheckOutlined className={classes.acceptIcon}/>
          <span className={classes.accept}>{t('Settings.terms.state.accepted')}</span>
        </div>;
      default: 
        return <div className={classes.statusTermWrap}>
          <ClockCircleOutlined className={classes.notAcceptIcon}/>
          <span className={classes.nonAccept}>{t('Settings.terms.state.nonAccepted')}</span>
        </div>;
    };
  };

  const uloadedNDA = (
    <div className={cn(classes.uploadedWrap, canEdit ? classes.canEdit : classes.cantEdit)}>
      <div onClick={() => setIsOpen!(true)} className={classes.cardNDA}>
        <div style={{display: 'flex', alignItems:'center'}}>
          <div style={{ fontSize: 32 }}>{documnentIcons[icon as keyof typeof documnentIcons]}</div>
          <div style={{ paddingLeft: 16 }}>{NDADocument && NDADocument[0]?.name}</div>
        </div>
        <div className={classes.cardView}><EyeOutlined /></div>
      </div>
      {getStatusRow(dataRoom?.user_status)}

      {canEdit && <div />}
    </div>
  );

  const iconRender = (file: UploadFile) => {
    const formatNDA = file?.name?.split('.')?.reverse()[0];  
    const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';
    return documnentIcons[icon as keyof typeof documnentIcons];
  };
  
  const onSave = async () => {
    if (NDADocument && !NDADocument[0]) {
      message.warning(t('Settings.error.fileUpload'));
      return;
    }
    setConfirmUploadingModal(false);
    setIsUpdateLoading(true);
    try {
      const formDataAgreement = new FormData() as any;
      formDataAgreement.append('room_id', dataRoom?.id);
      formDataAgreement.append('agreement_enable', String(true));
      NDADocument && formDataAgreement.append('agreement_file', NDADocument[0] as RcFile);
      await api.setRoomAgreement(formDataAgreement);        

      const response = await dispatch(fetchRoom({ id: dataRoom!.id }));
      const upadtedRoom = response.payload as IDataRoom;

      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const newRooms = userData?.available_rooms.map((room) =>
        room.id === upadtedRoom.id
          ? {
            ...upadtedRoom,
            created_at: dayjs(upadtedRoom.created_at).unix() * 1000,
            last_action_timestamp: UTCDate,
            permissions: room.permissions,
            agreement_enable: true,
          }
          : room
      ).sort((a,b) => b.last_action_timestamp - a.last_action_timestamp);
      dispatch(setRooms(newRooms || []));
      message.success(
        `${t('Settings.success.settingsUploaded.before')} ${dataRoom?.name}
        ${t('Settings.success.settingsUploaded.after')}`
      );
    } catch (err) {
      message.error(
        `${t('Settings.error.settingsUpload.before')} ${dataRoom?.name} 
        ${t('Settings.error.settingsUpload.after')}`
      );
    } finally {
      setIsUpdateLoading(false);
    }
  };

  return (
    <Card className={classes.card} title={t('Settings.terms.title')} bordered>
      {
        dataRoom?.agreement_enable
          ? uloadedNDA
          : <Dragger
            name='file'
            listType='picture'
            multiple={false}
            className={classes.dragger}
            disabled={NDADocument && !!NDADocument[0]}
            fileList={NDADocument}
            beforeUpload={onBeforeUpload}
            onRemove={onRemove}
            iconRender={iconRender}
            itemRender={itemRender}
            accept='.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf'
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>{t('Settings.terms.uploadFile.select')}</p>
            <p className='ant-upload-hint'>{t('Settings.terms.uploadFile.subtitle')}</p>
          </Dragger>
      }

      {NDADocument && NDADocument[0] && !dataRoom?.agreement_file && <Button
        loading={isUpdateLoading}
        type='primary'
        onClick={() => setConfirmUploadingModal(true)}
      >
        {t('main.saveButton')}
      </Button>}
      
      {confirmUploadingModal && (
        <React.Suspense fallback={<div />}>
          <ConfirmationModal
            isOpen={confirmUploadingModal}
            onCancel={() => setConfirmUploadingModal(false)}
            isLoading={isUpdateLoading}
            submit={onSave}
            okText={t('Settings.terms.confirmModal.okButton')}
            text={<div className={classes.textModal}>{t('Settings.terms.confirmModal.textNDA')}</div>}
            title={<div className={classes.titleRow}>
              <ExclamationCircleOutlined className={classes.exclamationIcon}/>{t('Settings.terms.confirmModal.title')}
            </div>}
          />
        </React.Suspense>
      )}
    </Card>
  );
};

export default RoomAccess;
