import { Button, Dropdown, MenuProps, message, Table } from 'antd'
import { TFunction } from 'i18next';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import classes from '../FileDetailsModal.module.scss';
import { IColumn, ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import { CheckOutlined, CloseOutlined, DownloadOutlined, EllipsisOutlined, PrinterOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/free-solid-svg-icons';
import { defaultGroups } from 'store/reducers/usersGroupCreator';
import { pdfHelper } from 'helpers/pdfHelper';
import dayjs from 'dayjs';
import cn from 'classnames';

interface IUserAgreement extends ISimpleUserForAprove {
  user_group: string;
  key: string
}

const getLocaleColumns = (t: TFunction) => [
  {
    title: t('Documents.details.agreement.user'),
    dataIndex: 'user_name',
    key: 'user_name',
    width: '30%',
  },
  {
    title: t('Documents.details.agreement.group'),
    dataIndex: 'user_group',
    key: 'user_group',
    width: '25%',
  },
  {
    title: t('Documents.details.agreement.email'),
    dataIndex: 'user_email',
    key: 'user_email',
    width: '25%',
  },
  {
    title: t('Documents.details.agreement.state'),
    dataIndex: 'agreement_state',
    key: 'agreement_state',
    width: '20%',
    className: classes.textToCenter,
  },
];

export enum IIconCases { Null = 'null', False = 'false', True = 'true' };
export const iconCases = (type: IIconCases, size?: 'small' | 'large') => {
  const sizeClass = size === 'small' ? classes.smallIcon : classes.largeIcon; 
  const icons = {
    [IIconCases.Null]: <FontAwesomeIcon className={cn(classes.glassWaiting, sizeClass)} icon={faHourglass} />,
    [IIconCases.False]: <CloseOutlined className={cn(classes.closeRed, sizeClass)} />,
    [IIconCases.True]: <CheckOutlined className={cn(classes.checkGreen, sizeClass)} />
  };
  return icons[type];
}

export default function AgreementUsers() {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);
  const [downloadProcess, setDownloadProcess] = React.useState<string[]>([]);

  const { isArgeementsLoading, agreements } = useAppSelector((store) => store.agreements);
  const { users, isGroupLoading } = useAppSelector((store) => store.dataUsers);
  const { selectedDocument } = useAppSelector((store) => store.documents);

  const { t } = useTranslation();
  
  const usersWithGroups = agreements?.reduce((acc: IUserAgreement[], currentGroup: ISimpleGroupForAprove) => {
    if (currentGroup.users) {
      const usersWithGroupAndFullName = currentGroup.users.map(user => ({
        ...user,
        user_group: currentGroup.group_name,
        user_name: `${user.user_name} ${users.find(curr => curr.email === user.user_email)?.last_name}`,
        key: user.user_email
      }))
      acc = [...acc, ...usersWithGroupAndFullName]
    }
    return acc
  }, [])

  React.useEffect(()=> {
    const tableColumns = getLocaleColumns(t);
    const customColumns = tableColumns.map((col: IColumn) => ({
      ...col, 
      render: (value: string, info: ISimpleUserForAprove) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[isGroupLoading, isArgeementsLoading]);

  const renderCell = (props: any) => {
    const { value, info, col } = props;
    if(col.key === 'agreement_state'){ 
      const iconKey = `${value}` as IIconCases;      
      return iconCases(iconKey);
    }

    if(col.key === 'user_group'){       
      return defaultGroups.includes(value)
        ? t(`Users.table.${value}`)
        : value
    }
   
    return value;
  };

  const getReport = async (type: string) => {
    setDownloadProcess(prev => [...prev, type]);
    // api.logPrint(dataRoom?.id!, false);

    const statusLocalized = {
      true: t('Documents.details.agreement.userAgreed'),
      false: t('Documents.details.agreement.userDeclinedAgreemnet'),
      null: t('Documents.details.agreement.userNotAgreed'),
    };
    
    try {
      const tableColumns = getLocaleColumns(t);
      const usersWithGroupsWithTextStatus = usersWithGroups.map(row => ({
        ...row,
        agreement_state: statusLocalized[String(row.agreement_state) as keyof typeof statusLocalized],
        user_group: defaultGroups.includes(row.user_group) ? t(`Users.table.${row.user_group}`) : row.user_group
      }));
      const currentTime =  dayjs(Date.now()).format('HH:mm DD-MM-YYYY');
      const currentTimeInUTC = dayjs(Date.now()).utc().format('HH:mm DD-MM-YYYY');
      const offset = - new Date().getTimezoneOffset()/60;
      const nameOfTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const slicedWord = selectedDocument?.name.slice(-selectedDocument?.name.length + 55, -12) || '';      
      const nameWithNeededLength = selectedDocument?.name && selectedDocument?.name.length > 80 
        ? selectedDocument?.name.replace(slicedWord, '/.../')
        : selectedDocument?.name;


      const titleText = [
        `${t('Documents.details.agreement.agreementTitle')} - ${nameWithNeededLength}`,
        `TazaDeal - ${currentTime} ${nameOfTimeZone}UTC+${offset} (${currentTimeInUTC}UTC+0)`
      ]

      pdfHelper<IUserAgreement>(
        usersWithGroupsWithTextStatus,
        tableColumns,
        titleText,
        type
      )
    } catch (e) {
      message.error(t(`Documents.details.agreement.${type}Error`));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === type));
    }
  };

  const actionItems: MenuProps['items'] = [
    {
      key: 'export',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('export')}
          onClick={() => getReport('export')}
          disabled={Boolean(downloadProcess[0])}
          icon={<DownloadOutlined />}
          className={classes.liButton}
        >
          {t('Confirm.download')}
        </Button>
      ),
    },
    {
      key: 'print',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('print')}
          onClick={() => getReport('print')}
          disabled={Boolean(downloadProcess[0])}
          icon={<PrinterOutlined />}
          className={classes.liButton}
        >
          {t('Confirm.print')}
        </Button>
      ),
    },
  ];

  return (
    <div className={classes.table}>
      
      <div className={classes.tableTitleWrap}>
        <span className={classes.title}>{t('Documents.details.agreement.agreementState')}</span>
        <Dropdown
          open={isOpenDropdown || Boolean(downloadProcess[0])}
          onOpenChange={(open) => setIsOpenDropdown(open)}
          menu={{ items: actionItems }}
          placement='bottomRight'
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      </div>
            
      {agreements[0] && <Table
        columns={modifiedColumns}
        dataSource={usersWithGroups}
        pagination={false}
        size='small'
        loading={isArgeementsLoading || isGroupLoading}
      />}
    </div>
  )
}
