import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Layout, Typography, Divider, Form, Input, Button, message, Select, Flex } from 'antd';
import { MailFilled, LockFilled } from '@ant-design/icons';
import { deleteCookie, setCookie } from 'helpers/cookies';
import { ILoginData } from 'interfaces';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { getBrowserClientData } from 'helpers/browserData/browserData';
import auth from 'api/auth';
import classes from './Login.module.scss';
import { TFAuthModal } from 'components/Modals';
import ConfirmEmail from './confirmEmail/ConfirmEmail';
import { languagesValues } from 'components/Header/HeaderComponent';

const { Link, Paragraph, Title } = Typography;

type LoginErrorType = {
  detail: string;
};

export default function Login() {
  const [isLaoding, setIsLoading] = React.useState<boolean>(false);
  const [isOpenTfa, setIsOpenTfa] = React.useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  
  const defaultLanguage = localStorage.getItem('language') || 'en';
  const emailToConfirm = searchParams.get('email');
  const needToOpenConfirm = searchParams.get('confirm_email');

  React.useEffect(() => {
    needToOpenConfirm === 'true' && emailToConfirm && setIsOpenConfirmModal(true);
  }, [])

  const onCancelTfa = () => setIsOpenTfa(false);

  const loginHandle = async (values: ILoginData) => {
    setIsLoading(true);
    try {
      setEmail(values.email)
      deleteCookie('athena-token');
      deleteCookie('refresh_token');

      let browserData: any = null;
      try {
        browserData = await getBrowserClientData();
      } catch (error) {
        console.log(error, 'error while collecting browser information');
      };

      const formData = new FormData();
      formData.append('email', values.email.toLocaleLowerCase());
      formData.append(
        'details',
        JSON.stringify({
          email: values.email.toLocaleLowerCase(),
        })
      );
      formData.append('browser_data', JSON.stringify(browserData) || '');
      formData.append('password', values.password);

      const response = await auth.login(formData);

      if(response.data.message){
        message.success(t('login.modals.verify.sendCode'));
        setIsOpenTfa(true);
        return;
      }

      setCookie('athena-token', response.data.access_token, 1);
      setCookie('refresh_token', response.data.refresh_token, 1);
      message.destroy();

      if (isMobile) navigate('/rooms')
      else navigate('/');

      // browserData && await api.sendBrowserFingerprint(form_data);
    } catch (err) {
      const error = err as AxiosError<LoginErrorType>;
      message.error(error.response?.data.detail ? t(`login.${error.response?.data.detail}`) : t('login.someError'), 15);
    } finally {
      setIsLoading(false);
    }
  };

  const { i18n, t } = useTranslation();

  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  return (
    <div className={classes.mainWrap}>
      {/* <div className={classes.image}>
        <Title level={3} className={classes.image_title}>
        TazaDeal
        </Title>
      </div> */}
      <Layout className={classes.loginElem}>
        <Select
          className={classes.localizationSelect}
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />
        <div className={classes.loginCard}>
          <Typography>
            <Title level={2}>{t('login.login')}</Title>
            <Paragraph className={classes.subTitle}>{t('login.info')}</Paragraph>
          </Typography>
          <Form className={classes.loginForm} onFinish={loginHandle}>
            <Form.Item name={'email'} rules={[{ required: true, message: t('login.inputEmail'), type: 'email' }]}>
              <Input
                placeholder={t('login.email')}
                size='large'
                prefix={<MailFilled style={{ fontSize: '90%' }} />}
                className={classes.input}
              />
            </Form.Item>
            <Form.Item name={'password'} rules={[{ required: true, message: t('login.inputPassword') }]}>
              <Input.Password
                placeholder={t('login.password')}
                size='large'
                prefix={<LockFilled />}
                className={classes.input}
              />
            </Form.Item>
            <Button htmlType='submit' loading={isLaoding}>
              {t('login.logIn')}
            </Button>
          </Form>
          <Divider />

          <Typography>
            <Flex className={classes.textWrap} gap='middle'>
              <div>
                {t('login.forgot')}{' '}
                <Link href='/reset' className={classes.primary}>
                  {t('resetPassword.reset')}
                </Link>
              </div>

              <Link href='/signup' className={classes.primary}>
                {t('login.signup')}
              </Link>

              {/* <Link href='/tariffs' className={classes.primary}>
                {t('login.tryFree')}
              </Link> */}
            </Flex>
          </Typography>
        </div>
      </Layout>

      {isOpenConfirmModal && (
        <React.Suspense fallback={<div />}>
          <ConfirmEmail
            isOpen={isOpenConfirmModal}
            onCancel={() => setIsOpenConfirmModal(false)}
            email={emailToConfirm}
          />
        </React.Suspense>
      )}

      {isOpenTfa && (
        <React.Suspense fallback={<div />}>
          <TFAuthModal email={email} isOpen t={t} onCancel={onCancelTfa} />
        </React.Suspense>
      )}
    </div>
  );
}
