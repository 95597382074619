import { InboxOutlined, WarningOutlined } from '@ant-design/icons';
import { Form, Input, message, Modal, UploadFile, UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React from 'react';
import classes from './NewVersionModal.module.scss';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { IDocument } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setDocuments } from 'store/slices/dataDocumentsSlice';
import api from 'api';
import classNames from 'classnames';

type NewVersionModalPops = {
  isOpen: boolean;
  onCancel: () => void;
  entity: IDocument;
};

const NewVersionModal = ({isOpen, onCancel, entity}: NewVersionModalPops) => {
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [isUploadingVersion, setIsUploadingVersion] = React.useState(false);
  const [isNeedAgreementWarn, setIsNeedAgreementWarn] = React.useState(false);
  const [form] = Form.useForm();  

  const { documents, folders, selectedDocument } = useAppSelector((store) => store.documents);
  
  React.useEffect(() => {
    setIsNeedAgreementWarn(selectedDocument?.agreement_status !== null)
  }, [selectedDocument]);

  const continueUploading = () => setIsNeedAgreementWarn(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const type = availableIconFormat.includes(entity?.extension || '') ? entity?.extension : 'default';
  const icon = type as keyof typeof documnentIcons;
  
  const onBeforeUpload = (file: UploadFile) => {
    setFileList([file]);
    return false;
  };

  const iconRender = (file: UploadFile) => {
    const formatNDA = file?.name?.split('.')?.reverse()[0];  
    const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';
    return documnentIcons[icon as keyof typeof documnentIcons];
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    className: classes.dragger,
    onRemove: (file) => {
      setFileList(fileList.filter((currentFile)=> file.uid !== currentFile.uid))
    },
    iconRender: iconRender,
    beforeUpload: onBeforeUpload,
    listType: 'picture',
    // accept: '.doc,.xls,.xlsx,.docx,.pdf,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    fileList,
  };
  
  const sendNewVersion = async (comment: string) => {
    setIsUploadingVersion(true);
    try{
      const formData = new FormData();
      formData.append('document', fileList[0] as RcFile);
      formData.append('parent_version_id', entity.id);
      comment && formData.append('comment', comment);

      const response = await api.uploadDocumentVersion({ formData: formData });
      form.resetFields(['comment']);

      dispatch(setDocuments({
        documents: documents.map(document => {
          return document.id === entity!.id
            ? { ...document, status: 0, name: response.data.name, id: response.data.id, extension: response.data.extension}
            : document
        }),
        folders
      }));

      message.success(t('Documents.details.versions.uploadVersionSuccessful'))
      onCancel()
    } catch (e: any) {      
      if (e?.response?.data?.message === 'Room has namesakes documents'){
        message.error(t('Documents.error.dublicate'),5);
        return;
      } else if (e?.response?.data?.message === "Can't find parent version of the document") {
        message.error(t('Documents.details.versions.cantFindParent'),5);
        return;
      }
      message.error(t('Documents.details.versions.uploadVersionError'));
    } finally {
      setIsUploadingVersion(false);
    }
  }
  const onFinish = (values: any) => {
    sendNewVersion(values.comment);
  };

  const modalTitle = (
    <div>
      <span>{t('Documents.modals.newVersion.uploadNewVersion')}</span>
      <span className={classes.modalTitleIcon}>{documnentIcons[icon]}</span>
      <span>{entity?.name}</span>
    </div>
  );

  return (
    <Modal 
      width={'70%'}
      open={isOpen}
      title={modalTitle}
      centered={true}
      okText={isNeedAgreementWarn ? t('Documents.modals.newVersion.continueUploading') : t('Documents.modals.newVersion.uploadButton')}
      okButtonProps={{disabled: !isNeedAgreementWarn && (!fileList[0] || isUploadingVersion), loading: isUploadingVersion}}
      onCancel={onCancel}
      onOk={isNeedAgreementWarn ? continueUploading : form.submit}
      className={classes.modal}
    >
      <div className={classNames(classes.info, isNeedAgreementWarn ? 'null' : classes.displayNone)}>
        <WarningOutlined className={classes.warningIcon}/>
        <div className={classes.text}>
        <p>{t('Documents.modals.newVersion.agreementWarn')}</p>
        </div>
      </div>
      
      <div className={classNames(classes.uploadHidden, isNeedAgreementWarn ? classes.displayNone : classes.elementApear)}>
        <Dragger {...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>{t('Documents.modals.newVersion.clickOrDrop')}</p>
        </Dragger>
        <Form onFinish={onFinish} form={form} layout='vertical' style={{paddingTop: 10}}>
          <Form.Item
            label={<span style={{fontWeight: 600}}>{t('Documents.modals.newVersion.comment')}</span>}
            name='comment'
          >
            <Input.TextArea
              disabled={!fileList[0]}
              placeholder={t('Documents.modals.newVersion.enterComment')}
              autoSize={{ maxRows: 10, minRows: 4 }}
            />
          </Form.Item>
        </Form>
      </div>
      
      {isUploadingVersion && <UploadingComponent />}
    </Modal>
  );
}

export default NewVersionModal;
