import React from 'react';
import NDASectionLoader from 'components/Sceletons/NDASectionLoader';
import { useAppDispatch, useAppSelector } from 'store/hook';
import PDFViewer from 'components/Webviewer/Viewer/PDFViewer';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import api from 'api';
import classes from './NDAElements.module.scss';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import dayjs from 'dayjs';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { useNavigate } from 'react-router-dom';

type NDAAgreementProps = {
  userAcceptNDA: boolean;
  changeTermStatus?: (status: number) => Promise<void>;
};

export default function NDAAgreement({ userAcceptNDA, changeTermStatus }: NDAAgreementProps) {
  const [isAvailableAccept, setIsAvailableAccept] = React.useState(false);
  const [isLoadingNewStatus, setIsLoadingNewStatus] = React.useState(false);
  const { dataRoom, isLoadingRooms} = useAppSelector((state) => state.dataRoom);
  const { isDocumentLoading, documentUrl } = useAppSelector((state) => state.viewer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onChangeStatus = React.useCallback( async (status: number, beforeStatus: number) => {
    setIsLoadingNewStatus(true);

    try {
      await api.setRoomStatus({ room_id: dataRoom?.id!, status, details: { before: beforeStatus, after: status }});
      status === 2
        ? message.success(t('Confirm.success'))
        : message.warning(t('Confirm.refuse'));

      dispatch(setDataRoom({
        ...dataRoom!,
        user_status: status,
        user_status_change_at: dayjs().format('DD.MM.YYYY, HH:mm'),
      }));
      // navigate(`/room/${dataRoom?.custom_url || dataRoom?.id}/documents`);
    } catch (err) {
      message.error(t('Confirm.error'));
    } finally {
      setIsLoadingNewStatus(false);
    }
  }, [dataRoom?.id]);

  const onChangeTermStatus = async (status: number) => {
    setIsLoadingNewStatus(true);
    try {
      await changeTermStatus!(status)
    } catch {} finally {
      setIsLoadingNewStatus(false);
    }
  }

  const onConfirmSubmit = async () => changeTermStatus
    ? await onChangeTermStatus(1)
    : await onChangeStatus(2, Number(dataRoom?.user_status));

  const onReject = async () => changeTermStatus
    ? await onChangeTermStatus(2)
    : await onChangeStatus(3, Number(dataRoom?.user_status));

  return (
  <div className={classes.tabWrap}>
    <div className={classes.container}>
      { 
        isLoadingRooms || isDocumentLoading || !documentUrl || !dataRoom
          ? <NDASectionLoader />
          : (
            <div>
              <div className={userAcceptNDA ? classes.withoutButtons : classes.withButtons}>
                <PDFViewer onClose={onReject} url={documentUrl} isModal={false} setIsAvailableAccept={setIsAvailableAccept}/>
              </div>
              {!userAcceptNDA && <div className={classes.controlButtons}>
                <Button disabled={!isAvailableAccept} type='primary' danger onClick={onReject}>
                  {t('Confirm.reject')}
                </Button>
                <Button disabled={!isAvailableAccept} type='primary' onClick={onConfirmSubmit}>
                  {t('Confirm.accept')}
                </Button>
              </div>}
            </div>
          )
      }
    </div>
    {isLoadingNewStatus && <UploadingComponent />}
  </div>
  )
}

