import RowLoader from 'components/Sceletons/RowLoader';
import { IUsers } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import ContactsCard from './ContactsCard';
import classes from './ContactsTab.module.scss';

function ContactsTab() {
  const { globalUsers, isGroupLoading } = useAppSelector((state) => state.dataUsers);
  const { t } = useTranslation();

  return (
    <div className={classes.usersTab}>
      <div className={classes.usersWrap}>
        {isGroupLoading ? (
          <div style={{ marginRight: 10 }}>
            <RowLoader width={600} padding={'0 0 0 0'} height={70} />
            <RowLoader width={600} marginTop={10} padding={'0 0 0 0'} height={70} />
            <RowLoader width={600} marginTop={10} padding={'0 0 0 0'} height={70} />
          </div>
        ) : (
          <div className={classes.usersWrap}>
            {globalUsers[0] ? (
              globalUsers.map((user) => (
                <div key={user.id}>
                  <ContactsCard user={user as IUsers} />
                </div>
              ))
            ) : (
              <div className={classes.noFound}>{t('Users.Tabs.noFound')}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactsTab;
