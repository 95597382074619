import { Timeline } from 'antd';
import { getTimelineTabs } from './timelineTabs';
import { ILogs } from 'interfaces';
import {
  CheckOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FolderViewOutlined,
  HighlightOutlined,
  KeyOutlined,
  PushpinOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { TFunction, i18n } from 'i18next';
import { getMockActions } from 'helpers/getMockActions';
import dayjs from 'dayjs';

const icons = {
  download: (
    <div className='logs_icon'>
      <CloudDownloadOutlined /> 
    </div>
  ),
  view: (
    <div className='logs_icon'>
      <FolderViewOutlined />
    </div>
  ),
  create: (
    <div className='logs_icon'>
      <UploadOutlined />
    </div>
  ),
  GET: (
    <div className='logs_icon'>
      <VerticalAlignBottomOutlined />
    </div>
  ),
  update: (
    <div className='logs_icon'>
      <CheckOutlined />
    </div>
  ),
  upload: (
    <div className='logs_icon'>
      <UploadOutlined />
    </div>
  ),
  trigger: (
    <div className='logs_icon'>
      <PushpinOutlined />
    </div>
  ),
  view_confident: (
    <div className='logs_icon'>
      <KeyOutlined />
    </div>
  ),
  nda_view: (
    <div className='logs_icon'>
      <FileProtectOutlined />
    </div>
  ),
  delete: (
    <div className='logs_icon'>
      <DeleteOutlined />
    </div>
  ),
  create_confident: (
    <div className='logs_icon'>
      <FileDoneOutlined />
    </div>
  ),
  ALL: null,
};

const offset = new Date().getTimezoneOffset() * 60 * 1000;

const prepareTimeLines = (
  logs: ILogs[],
  mockActions: {ru: string[]; actual: string[]},
  mode?: 'left' | 'alternate' | 'right',
) => {
  const dataTimeLine = logs.map((log: ILogs) => {
    const inSeconds = dayjs(log.timestamp, 'DD.MM.YYYY, hh:mm:ss').valueOf();
    const rightTime = dayjs(inSeconds - offset).format('DD.MM.YYYY, HH:mm:ss');
    
    const dotICon = icons[log.type] || (
      <div className='logs_icon'>
        <HighlightOutlined />
      </div>
    );
        
    return {
      label: mode === 'alternate' ? null : rightTime,
      dot: dotICon,
      children: (
        <>
          <b>{log.name}</b>
          <br />
          <i>{log?.entity?.name}</i>
          <br />
          { mode === 'alternate'
            ? <>{rightTime}<br /></>
            : null
          }
          {log?.user?.name} - {log?.user?.email}
          {
            log?.details?.ip && <>
            <br />
            ip - {log?.details?.ip}
            </>
          }
        </>
      ),
    };
  });

  return <Timeline mode={mode ? mode : 'left'} items={dataTimeLine}/>;
};

const prepareTabs = (logs: ILogs[], t: TFunction, i18n: i18n, mode?: 'left' | 'alternate' | 'right') => {
  const staticRuT = i18n.getFixedT('ru');
  const mockActions = getMockActions(staticRuT, t);

  const items = getTimelineTabs(t)?.map((tab) => {
    const filteredLogs = logs.filter((log) => {
      if (tab.key === 'ALL') return true;
      return tab.key === log.type;
    });

    const timeLines = prepareTimeLines(filteredLogs, mockActions, mode);

    return {
      ...tab,
      children: <div style={{paddingTop: 20}}>{timeLines}</div>,
    };
  });

  return items;
};

export { prepareTabs };
