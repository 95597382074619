import React from 'react'
import { Empty, Table } from 'antd';
import { getNDADocColumns } from './columns/NDADocColumns';
import { useTranslation } from 'react-i18next';
import classes from './NDAElements.module.scss';
import TableLoader from 'components/Sceletons/TableLoader';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchConfidentialDocuments } from 'store/reducers/roomsCreator';
import { IColumn } from 'interfaces';
import { documnentIcons } from 'helpers/documentIcons';

export default function NDADocuments() {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);

  const { confidentialDocuments, isLoadingConfidentialDocuments, isLoadingRooms, dataRoom } = useAppSelector((state) => state.dataRoom);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dataRoom?.id && dispatch(fetchConfidentialDocuments({room_id: dataRoom?.id}))
  }, [dataRoom?.id]);

  React.useEffect(()=> {
    const NDADocColumns = getNDADocColumns(t);
    const customColumns = NDADocColumns.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[confidentialDocuments, i18n.language]);


  const renderCell = (props: any) => {
    const { value, info, col } = props;    

    if(col.key === 'name'){
      const icon = info.extension as keyof typeof documnentIcons;
      return <div className={classes.rowWrap}>{documnentIcons[icon]} {value}</div>;
    }
   
    return value;
  }

  if (isLoadingRooms || isLoadingConfidentialDocuments) {   
    return (
      <div
        className={classes.tabWrap}
        style={{height: 'calc(100vh - 256px)', padding: 0}}
      >
        <TableLoader height={'calc(100vh - 212px)'}/>
      </div>
    )
  };

  return (
    <div
      className={classes.tabWrap}
      style={{ height: 'calc(100vh - 256px)', padding: 15 }}
    >
      <Table
        style={{backgroundColor: 'white'}}
        dataSource={[...confidentialDocuments]}
        columns={modifiedColumns}
        pagination={false}
        scroll={{
          y: 'calc(100vh - 335px)',
          // x: '900px'
        }}
        size='middle'
        locale={{
          emptyText: <Empty
            style={{ height: 'calc(100vh - 395px)', paddingTop: 100 }}
            description={t('Confirm.NDADoc.emptyTable')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        }}
      />
    </div>
  )
}
