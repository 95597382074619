import React from 'react'
import classes from '../ConfigureRoles.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { Button, Input, message, Tree } from 'antd';
import { DataNode } from 'antd/es/tree';
import RowLoader from 'components/Sceletons/RowLoader';
import { IDataUserTable, IPermission, IUsers } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { defaultGroups } from 'store/reducers/usersGroupCreator';
import { DeleteOutlined } from '@ant-design/icons';
import ConfirmationModal from 'components/Modals/ConfirmationModal/ConfirmationModal';
import { setUserAndData } from 'store/slices/dataUsersSlice';
import api from 'api';

type GroupsListType = {
  configurableRole: IDataUserTable | null;
  setConfigurableRole: React.Dispatch<React.SetStateAction<IDataUserTable | null>>;
};

export default function GroupsList({configurableRole, setConfigurableRole}: GroupsListType) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isOpenDeleteGroupModal, setIsOpenDeleteGroupModal] = React.useState(false);
  const [isDeletingGroup, setIsDeletingGroup] = React.useState(false);
  const [targetGroup, setTargetGroup] = React.useState<IDataUserTable | IUsers | null>(null);

  const isFirstRenderState = React.useRef(true); 
  const skeletonRows = [...new Array(2)].map((_, index) => (<RowLoader key={index} height={35} marginBottom={'12px'} width={230} padding={'0 0 0 20px'} />));
  const { dataUserTable, isGroupLoading, groups, users } = useAppSelector((state) => state.dataUsers);
  const { userPermissions } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const deleteHandle = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    const targetGroup = dataUserTable.find(elem => elem.id === id);
    targetGroup && setTargetGroup(targetGroup);
    setIsOpenDeleteGroupModal(true);
  }

  const closeDeleteModal = () => setIsOpenDeleteGroupModal(false);
  
  const onDeleteSubmit = async () => {
    setIsDeletingGroup(true);
    try {
      await api.deleteGroup({group_id: targetGroup?.id!, room_id: dataRoom?.id!})
      const filteredGroups = groups.filter(group => group.id !== targetGroup?.id);
      const filteredTableData = dataUserTable.filter(elem => elem.id !== targetGroup?.id);
      dispatch(setUserAndData({
        users: users,
        dataUserTable: filteredTableData,
        groups: filteredGroups,
       }));

      message.success(`
        ${t('Users.modals.configureRoles.successDeleteTextBefore')}
        "${targetGroup?.name}" 
        ${t('Users.modals.configureRoles.successDeleteTextAfter')}
      `);
    } catch (e) {
      message.error(`
        ${t('Users.modals.configureRoles.errorDeleteTextBefore')}
        "${targetGroup?.name}" 
        ${t('Users.modals.configureRoles.errorDeleteTextAfter')}
      `);
    } finally {
      setIsDeletingGroup(false);
      setIsOpenDeleteGroupModal(false);
      setTargetGroup(null);
      if (configurableRole?.id === targetGroup?.id) setConfigurableRole(null);
    }
  }

  React.useEffect(() => {
    if (dataUserTable[0] && isFirstRenderState.current) {
      setConfigurableRole(dataUserTable[0] as IDataUserTable);
      isFirstRenderState.current = false;
    }
  }, [dataUserTable]);

  const filteredDataUserTable = React.useMemo(
    () =>
      dataUserTable.filter(({ name }) =>
        t(`Users.table.${name}`)?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ),
    [dataUserTable, searchTerm]
  );

  const treeData = filteredDataUserTable.map(group => ({
    title: (
      <div className={classes.titleGroup}>
        {defaultGroups.includes(group.name!) ? t(`Users.table.${group.name}`) : group.name}
        {!defaultGroups.includes(group.name!) && userPermissions.can_edit_roles &&
          <Button onClick={(e) => deleteHandle(e, group.id)} size='small' type='link' icon={<DeleteOutlined className={classes.deleteIcon} />}/>
        }
      </div>
    ),
    key: group.id,
    children: group.children ? group.children?.map(user => ({
      title: `${user.first_name} ${user.last_name}`,
      key: user.id,
      selectable: false,
    })) : null
  }));

  const selectGroup = ( selectedKeys: React.Key[],  _: any ) => {
    const targetGroup = dataUserTable.find(group => group.id === selectedKeys[0]);
    setConfigurableRole(targetGroup as IDataUserTable);
  };

  const creatingNewRole = () => {
    setConfigurableRole({
      id: 'new',
      name: '',
      role: {
        id: 'new',
        name: 'new',
        permissions: []
      }
    })
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }

  return (
    <div className={classes.groupsSection}>
      <div className={classes.search}>
        <Input value={searchTerm} onChange={onSearch} placeholder={t('Users.modals.configureRoles.searchRole')} />
      </div>

      {isGroupLoading
        ? skeletonRows
        : <Tree
          treeData={treeData as DataNode[]}
          onSelect={selectGroup}
          defaultSelectedKeys={configurableRole ? [configurableRole.id] : []}
          selectedKeys={configurableRole?.id ? [configurableRole?.id] : []}
        />
      }
      <Button
        onClick={creatingNewRole}
        className={classes.addRole}
        type='dashed'
      >
        {t('Users.modals.configureRoles.newRole')}
      </Button>

      {isOpenDeleteGroupModal && (
        <React.Suspense fallback={<div />}>
          <ConfirmationModal
            isOpen={isOpenDeleteGroupModal}
            onCancel={closeDeleteModal}
            isLoading={isDeletingGroup}
            submit={onDeleteSubmit}
            text={`${t('Users.modals.configureRoles.deleteTextBefore')} "${targetGroup?.name}" 
            ${t('Users.modals.configureRoles.deleteTextAfter')}?`}
            title={t('Users.modals.configureRoles.deleteTitle')}
          />
        </React.Suspense>
      )}
    </div>
  );
}
