import React from 'react';
import { Table } from 'antd';
import { useAppSelector } from 'store/hook';
import { getPermissionsColumns } from './columns/permissionsColumns';
import TableLoader from 'components/Sceletons/TableLoader';
import { IColumn, IDocument, IFolder } from 'interfaces';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import ExistingGroups from 'components/PermissionsTable/ExistingGroup/ExistingGroup';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import downloadCascadePermissionCases from './cascadePermissionsRules/canDownload';
import classes from './PermissionsTable.module.scss';

type PermissionsTableProps = {
  dataWithRootFolder: IFolder[];
  setDataWithRootFolder: React.Dispatch<React.SetStateAction<IFolder[]>>;
  heightTable?: string;
  scrollTable?: string;
  scrollLoader?: string;
  parent?: string;
  choosenGroup?: string | null;
  setChoosenGroup?: React.Dispatch<React.SetStateAction<string | null>>;
};

export default function PermissionsTable({dataWithRootFolder, setDataWithRootFolder, heightTable, scrollTable, scrollLoader, parent, choosenGroup, setChoosenGroup}: PermissionsTableProps) {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const { isGroupLoading, groups } = useAppSelector((state) => state.dataUsers);
  const { isLoadingPermissions, loadingFolders } = useAppSelector((state) => state.permissions);  

  const { t } = useTranslation();

  const isFullAdminGroup = groups.find(group => group.id === choosenGroup)?.group_name === 'full_administrator';
  const isLoadPermissionsIcons = isLoadingPermissions || isGroupLoading;

  React.useEffect(()=> {
    const permissionsColumns = getPermissionsColumns(t);
    const customColumns = permissionsColumns.map((col: IColumn) => ({
      ...col, 
      title: (col.key === 'name' && parent === 'assignPermissions') 
        ? <ExistingGroups choosenGroup={choosenGroup} setChoosenGroup={setChoosenGroup}/> 
        : col.title,
      render: (value: string, info: IFolder | IDocument) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[isLoadingPermissions, isGroupLoading]);

  const onPermissionClick = (info: IFolder, colKey: string) => {
    if (isFullAdminGroup) return;
    if (colKey === 'none_column') {
      setDataWithRootFolder((prev: IFolder[]) => prev.map(folder => folder.key === info.key 
        ? {
          ...folder,
          permissions: [],
          children: folder.children ? folder.children.map(document => ({...document, permissions: []})) : null
        }
        : folder
      ));
    } else {
      setDataWithRootFolder((prev: IFolder[]) =>
        prev.map((folder) => {
          if (folder.key === info.key && folder.permissions?.includes(colKey)) {
            const newPermissions = (folder.permissions as string[])?.filter(
              (permission: string) => permission !== colKey
            );
              const isCanView = newPermissions.length !== 0;
              isCanView && newPermissions.push('can_view');

            return {
              ...folder,
              permissions: newPermissions,
              children: folder.children
                ? folder.children.map((document) => ({
                    ...document,
                    permissions: newPermissions,
                  }))
                : null,
            };
          } else if (folder.key === info.key) {
            const newPermissions = [...(folder.permissions as string[]), colKey];
            const isCanDownload = downloadCascadePermissionCases
              .map((permissions) => permissions.every((el) => newPermissions.includes(el)))
              .includes(true);

            const isCanView = newPermissions.length !== 0;

            isCanDownload && newPermissions.push('can_download');
            isCanView && newPermissions.push('can_view');
            Boolean(newPermissions.includes('can_download') || isCanDownload) &&
            newPermissions.push('can_download_pdf');

            return {
              ...folder,
              permissions: newPermissions,
              children: folder.children
                ? folder.children.map((document) => ({
                    ...document,
                    permissions: newPermissions,
                  }))
                : null,
            };
          } else {
            return folder;
          }
        })
      );
    }
  }
  
  const renderCell = (props: { value: string, info: IFolder | IDocument, col: IColumn}) => {
    const { value, info, col } = props;
    const icon = availableIconFormat.includes(info.type!) ? info.type : 'default';    

    const isClickable = parent === 'assignPermissions'// || parent === 'newFolderModal'
      ? true
      : ((info.type && info.type?.includes('folder')) || info.can_edit_permissions) && !isFullAdminGroup;
      
    if (col.key === 'name') {
      if (info.type === 'role') {
        return (<div>
          <FontAwesomeIcon
            style={{ fontSize: 18, paddingRight: 6}}
            color={'lightblue'}
            icon={faUserGroup}
          />
          {value}
        </div>);
      }
      
      return info.key === 'rootFolder'
        ? <div className={classes.rootFolder}>{documnentIcons[icon as keyof typeof documnentIcons]} {value}</div>
        : <div>{documnentIcons[icon as keyof typeof documnentIcons]} {value}</div>;
    }    

    if (isLoadPermissionsIcons) return <div className={classes.iconWrap}> <Spinner size={28} /> </div>

    if (col.key === 'none_column') {
            
      return (
        <div
          onClick={() => isClickable && onPermissionClick(info, col.key)}
          className={cn(classes.iconWrap, isClickable ? classes.hovered : classes.nonHovered )}
        >
          <CloseOutlined 
            className={classes.icon} 
            style={{
              color: info.type && (info.type?.includes('folder') || info.type?.includes('role'))
                ? (info.permissions?.length === 0 ? 'red' : 'lightgrey' )
                : (info.permissions?.length === 0 ? 'grey' : 'transparent' )
            }}
          />
        </div>
      )
    }
   
    return (
      <div
        onClick={() => isClickable && onPermissionClick(info, col.key)}
        className={cn(classes.iconWrap, isClickable ? classes.hovered : classes.nonHovered )}
      >
        <CheckOutlined 
          className={classes.icon}
          style={{
            color: info.type && (info.type?.includes('folder') || info.type?.includes('role'))
              ? (info.permissions?.includes(col.key) ? 'green' : 'lightgray' )
              : (info.permissions?.includes(col.key) ? 'grey' : 'transparent' )
          }}
        />
      </div>
    );
  }

  if (loadingFolders || (parent === 'newFolderModal' && isGroupLoading)) {   
    return <TableLoader height={scrollLoader}/>
  };  
  
  return (
    <div
      className={cn(classes.tableArea, parent === 'newFolderModal' ? classes.noPadding : classes.somePadding)}
      style={{ maxHeight: heightTable }}
    >
      <Table
        style={{backgroundColor: 'white'}}
        dataSource={dataWithRootFolder}
        columns={modifiedColumns}
        pagination={false}
        scroll={{y: scrollTable, x: '900px'}}
        size='large'
        rowClassName={classes.permisssionsRow}
      />
    </div>
  )
}
