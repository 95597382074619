import { requestTemplate } from 'api';
import { IDocument } from 'interfaces';

// TERMS OF ACCESS
const getTermsOfAccess = (room_id: string) => requestTemplate.get<IDocument[]>(`/documents/terms_of_access/?room_id=${room_id}`);

const uploadTerm = (
  payload: FormData,
) => requestTemplate.post<IDocument>(`/documents/terms_of_access/`, payload, {
   headers: { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' }
});

const deleteTermOfAccess = (document_id: string, room_id: string) => requestTemplate.delete(`/documents/terms_of_access/?document_id=${document_id}&room_id=${room_id}`);

const getStateOfTerm = (document_id: string, room_id: string) => requestTemplate.get(`/documents/terms_of_access/${document_id}/?room_id=${room_id}`);

const acceptTerm = (
  payload: {document_id: string, room_id: string}
) => requestTemplate.post(`/documents/terms_of_access/accept/?document_id=${payload.document_id}&room_id=${payload.room_id}`);

const rejectTerm = (
  payload: {document_id: string, room_id: string}
) => requestTemplate.post(`/documents/terms_of_access/decline/?document_id=${payload.document_id}&room_id=${payload.room_id}`);

const terms = {
  getTermsOfAccess,
  uploadTerm,
  deleteTermOfAccess,
  getStateOfTerm,
  acceptTerm,
  rejectTerm,
};

export default terms;
