import { Form, Input, Modal, message } from 'antd';
import api from 'api';
import dayjs from 'dayjs';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { TFunction } from 'i18next';
import { IDataRoom } from 'interfaces';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setRooms } from 'store/slices/userDataSlice';
import classes from './RoomAction.module.scss';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { setRoomAllChats } from 'store/slices/chatsSlice';
import { createSettingLog } from 'routes/Settings/createSettingLog';

type Props = {
  room: IDataRoom;
  t: TFunction;
  isOpen: boolean;
  onCancel: () => void;
};

export default function EditRoomModal({ room, t, isOpen, onCancel }: Props) {
  const [fields, setFields] = useState({ name: room.name, description: room.description });
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [form] = Form.useForm();
  const { userData } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { roomAllChats } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();

  const onFinish = async (values: any) => {
    try {
      if(!(values.name as string).trim()) return;
      
      setIsEditLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description || '');
      formData.append('details', JSON.stringify(createSettingLog(dataRoom!, {...dataRoom!, name: values.name, description: values.description})));

      const response = await api.setRoomSettings(formData, room.id, 'edit', '');
      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const newRooms = userData?.available_rooms.map((room) =>
        room.id === response.data.id
          ? {
            ...response.data,
            created_at: dayjs(response.data.created_at).unix() * 1000,
            last_action_timestamp: UTCDate,
            permissions: room.permissions,
          }
          : room
      ).sort((a,b) => b.last_action_timestamp - a.last_action_timestamp);
      dispatch(setRooms(newRooms || []));
      dispatch(setDataRoom({
        ...dataRoom!, name: response.data.name, description: response.data.description || ''
      }));
      dispatch(
        setRoomAllChats(
          roomAllChats.map((chat) =>
            chat.entity_type === 1 ? { ...chat, title: response.data.name, entity_name: response.data.name } : chat
          )
        )
      );
      message.success(t('Rooms.success.edit'));
      onCancel();
    } catch (err) {
      message.error(t('Rooms.errors.edit'));
    } finally {
      setIsEditLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: room.name,
      description: room.description,
    });
  }, []);

  const onInputsChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setFields((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });

  return (
    <Modal
      title={t('Rooms.modals.edit.title')}
      className={classes.modals}
      centered
      open={isOpen}
      okText={t('Rooms.modals.edit.save')}
      cancelText={t('Rooms.modals.edit.cancel')}
      onCancel={onCancel}
      onOk={form.submit}
      okButtonProps={{
        disabled: room.name === fields.name && room.description === fields.description,
        loading: isEditLoading,
      }}
    >
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <Form.Item name={'name'} label={t('Settings.room.labels.name')}>
          <Input
            placeholder={t('Settings.room.placeholders.name')}
            name='name'
            count={{
              show: true,
              max: 80,
            }}
            maxLength={80}
            value={fields.name}
            onChange={onInputsChange}
          />
        </Form.Item>

        {/* <Form.Item name={'description'} label={t('Settings.room.labels.description')}>
          <Input.TextArea
            placeholder={t('Settings.room.placeholders.description')}
            name='description'
            value={fields.description}
            onChange={onInputsChange}
          />
        </Form.Item> */}
      </Form>
      {isEditLoading && <UploadingComponent />}
    </Modal>
  );
}
