import { DeleteOutlined, FontColorsOutlined, SwapOutlined, InfoCircleOutlined, ShareAltOutlined, BranchesOutlined, FileAddOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import cn from 'classnames'
import { TFunction } from 'i18next'
import { IDocument, IFolder } from 'interfaces'
import React from 'react'
import classes from './ContextMenu.module.scss'
import { useAppDispatch, useAppSelector } from 'store/hook'
import { typesToSigning, typesWithoutVersions } from 'components/DocumentTable/DocumentTable';
import { useTranslation } from 'react-i18next'
import { getFile } from 'helpers/downloadSomeFile'
import convert from 'api/convert'
import api from 'api'
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons'
import { setDataDocumentTable } from 'store/slices/dataDocumentsSlice'

type ContextMenuProps = {
  entity: IDocument | IFolder;
  t: TFunction;
  deleteModalHandle: () => void;
  moveModalOpenHandle: () => void;
  renameModalHandle: () => void;
  shareModalHandle?: () => void;
  detailsModalHandle: (e: React.MouseEvent<HTMLElement>, tab?: string) => void;
  newVersionHandle?:  () => void;
};

export default function ContextMenu({
  entity, t,
  deleteModalHandle,
  moveModalOpenHandle,
  renameModalHandle,
  shareModalHandle,
  detailsModalHandle,
  newVersionHandle,
}: ContextMenuProps) {
  const { currentFolder, folders, dataDocumentTable } = useAppSelector(store => store.documents);
  const { userFoldersPermissions, userRootPermissions } = useAppSelector((store) => store.permissions);
  const { userPermissions } = useAppSelector(store => store.userData);
  const { dataRoom } = useAppSelector(store => store.dataRoom);

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const isRootFolder = !currentFolder[0];

  const targetFolderPermissions = (isRootFolder && userRootPermissions)
    ? userRootPermissions.permissions
    : userFoldersPermissions?.find(folder => folder.id === currentFolder[0]?.id)?.permissions;  
    
  const isEntityForbiddenToDelete = !entity.permissions?.includes('can_delete');
  const isEntityForbiddenToShare = !entity.permissions?.includes('can_sharing') || entity.is_confidential;
  const isAvailableMoveDocuments = targetFolderPermissions?.includes('can_delete');
  const isAvailableFoldersToMove = Boolean(currentFolder[0] || folders[0])
  const isAvailableEditingFolders = userPermissions.can_edit_folder_permissions;
  const isAvailableViewingVersions = !typesWithoutVersions.includes(entity.extension);
  const isAvailableUploadNewVersion = isAvailableViewingVersions && targetFolderPermissions?.includes('can_upload')
  const isAvailableDownload = [...typesToSigning, 'xls', 'xlsx'].includes(entity.extension)
    ? entity?.permissions?.includes('can_download') || entity?.permissions?.includes('can_download_pdf')
    : entity?.permissions?.includes('can_download');

  const getCorrectIcon = (extention: string) => {
    const typeVersion = availableIconFormat.includes(extention) ? extention : 'default';
    const iconVersion = typeVersion as keyof typeof documnentIcons;
    return iconVersion as keyof typeof documnentIcons
  }

  const downloadFile = async (version: IDocument) => {
    dispatch(setDataDocumentTable(dataDocumentTable.map(element => element.id === version.id 
      ? { ...element, status: 0, isOpenContextMenu: false, } : { ...element, isOpenContextMenu: false }
    )));

    const downloadPayload = {
      extension: version.extension,
      token: version.token,
      id: dataRoom?.id,
      entity: version.id,
      action: version.is_confidential ? 'download_confident' : 'download',
      is_agreement: false,
      is_webview: false,
      signing_status: version.signing_status,
      locale: i18n.language
    };

    const downloadInPDF = !entity?.permissions?.includes('can_download') && entity?.permissions?.includes('can_download_pdf');
    const iconOfDownloadingVersion = downloadInPDF ? 'pdf' : version?.extension || '';
    const correctFileName = downloadInPDF ? version.name.replace(/\.\w+$/, '.pdf') : version.name
    
    try {
      const file = downloadInPDF
        ? await convert.convertDocument(downloadPayload)
        : await api.downloadFile(downloadPayload);
      
      getFile(file.data as Blob, correctFileName);
      
      message.success(<div>
        <span>{documnentIcons[getCorrectIcon(iconOfDownloadingVersion)]}</span> {correctFileName} - {t('Documents.contextMenu.successDownload')}
      </div>, 5);
    } catch {
      message.error(t('Documents.contextMenu.errorDownload'));
    } finally {
      dispatch(setDataDocumentTable(dataDocumentTable.map(element => element.id === version.id 
        ? { ...element, status: 1 } : element
      )));
    }
  };
  
  return (
    <>
      {entity.type === 'folder' ?
        <div>
          <Button
            onClick={deleteModalHandle}
            icon={<DeleteOutlined />}
            className={classes.button}
            disabled={isEntityForbiddenToDelete}
          >
            {t('Documents.contextMenu.delete')}
          </Button>
          <Button
            icon={<FontColorsOutlined />}
            className={classes.button}
            onClick={renameModalHandle}
            disabled={!isAvailableEditingFolders}
          >
            {t('Documents.contextMenu.rename')}
          </Button>
        </div>
        : <div>
          {/* <Button
            disabled
            icon={<FontColorsOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.rename')}
          </Button> */}
          <Button
            onClick={moveModalOpenHandle}
            icon={<SwapOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={!isAvailableMoveDocuments || !isAvailableFoldersToMove}
          >
            {t('Documents.contextMenu.move')}
          </Button>
          <Button
            onClick={deleteModalHandle}
            icon={<DeleteOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={isEntityForbiddenToDelete}
          >
            {t('Documents.contextMenu.delete')}
          </Button>
          <Button
            onClick={shareModalHandle}
            icon={<ShareAltOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={isEntityForbiddenToShare}
          >
            {t('Documents.contextMenu.sharing')}
          </Button>
          <Button
            onClick={(e) => detailsModalHandle!(e)}
            icon={<InfoCircleOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.details')}
          </Button>

          {isAvailableDownload && <Button
            onClick={() => downloadFile(entity as IDocument)}
            icon={<DownloadOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.download')}
          </Button>}

          {isAvailableViewingVersions && <Button
            onClick={(e) => detailsModalHandle!(e, 'versions')}
            icon={<BranchesOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.versions')}
          </Button>}

          {isAvailableViewingVersions && Boolean(entity.signing_status === null) && <Button
            onClick={newVersionHandle}
            icon={<FileAddOutlined />}
            className={classes.button}
            disabled={!isAvailableUploadNewVersion}
          >
            {t('Documents.contextMenu.addVersions')}
          </Button>}
        </div>
      }
    </>
  )
}
