import { MessageOutlined } from '@ant-design/icons';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { IUsers } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { createChat } from 'store/reducers/chatCreator';
import classes from './ContactsTab.module.scss';

type ContactsCardProps = {
  user: IUsers;
};

const ContactsCard = ({ user }: ContactsCardProps) => {
  const markerTitle = React.useRef<HTMLDivElement>(null);
  const markerDesc = React.useRef<HTMLDivElement>(null);
  const [installingNewRole, setInstallingNewRole] = React.useState(false);

  const {  userData } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();  

  const onChatClick = async (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    const { payload } = (await dispatch(
      createChat({
        data: {
          members_ids: [userData?.id!, user.id],
          entity_type: 2,
          room_id: dataRoom?.id!,
          title: user.email,
        },
        ownerId: userData?.id!,
        oponentName: `${user.first_name} ${user.last_name}`
      })
    )) as any;
    const pathTypes = [`document/${payload.chat.id}`, ``, `user/${payload.chat.id}`];
    navigate(`/room/${dataRoom?.custom_url || dataRoom?.id}/chat/${pathTypes[payload.chat.entity_type]}`);
  };

  const { t } = useTranslation();
  const isRoomOwnerCard = user?.id === dataRoom?.owner_id;

  const fullName = `${user.first_name} ${user.last_name}`;


  return (
    <div
      className={classNames(classes.userCard, installingNewRole ? classes.loading : null)}
    >
      <div className={classes.jusctifySpace}>
        <div ref={markerTitle} className={classes.userTitle}>
          <Tooltip title={fullName} mouseEnterDelay={1}>
            {fullName}
          </Tooltip>
        </div>

        <div>
          {/* {userData?.email !== user.email && (
            <Tooltip title={t('Users.HelperMenu.userChat')} zIndex={1000}>
              <MessageOutlined onClick={onChatClick} />
            </Tooltip>
          )} */}

        </div>
      </div>
      <div className={classes.jusctifySpace}>
        <div ref={markerDesc} className={classes.userDescription}>
          <Tooltip title={user.email} mouseEnterDelay={1}>
            {user.email}
          </Tooltip>
        </div>

      </div>
    </div>
  );
};

export default ContactsCard;
