import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import classes from './NDASection.module.scss';
import AccessTerms from './AccessTerms/AccessTerms';
import RoomAccess from './RoomAccess/RoomAccess';
import ModalNDA from './ModalNDA/ModalNDA';

const NDASection = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userPermissions } = useAppSelector((state) => state.userData);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setSelectedKeys(['nda']));
  }, [dataRoom?.agreement_file]);

  return (
    <div className={classes.confirm}>

      {(dataRoom?.agreement_enable || userPermissions?.can_edit_room_settings) && <AccessTerms />}
      {(dataRoom?.agreement_enable || userPermissions?.can_edit_room_settings) && <RoomAccess setIsOpen={setIsModalOpen}/>}

      {isModalOpen && (
        <React.Suspense fallback={<div />}>
          <ModalNDA
            open={isModalOpen}
            setIsOpen={setIsModalOpen}
          />
        </React.Suspense>
      )}
    </div>
  );
};

export default NDASection;
