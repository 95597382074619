import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import dayjs from 'dayjs';
import { dateChecker } from 'helpers/dateChecker';
import { chatDataType, IChat, IChatMessage, IChatPayload, messageStatusType } from 'interfaces';
import { RootState } from 'store';
import {
  setAttemptCount,
  setChatData,
  setCheckingMessages,
  setSelectedChatRoom,
  setUnviewedAmount,
  setViewsMessagesIds,
} from 'store/slices/chatsSlice';
import { setDocuments } from 'store/slices/dataDocumentsSlice';

type createChatPayloadType = { data: IChatPayload; ownerId: string; oponentName?: string };
enum StatusActions {
  ADD = 'add',
  REPLACE = 'replace',
  REJECTED = 'rejected',
}

const getCheckedChat = (
  { data: mock, type }: { data: IChatMessage; type: 'replace' | 'add' | 'cancel' },
  chatData: IChatMessage[],
  status?: messageStatusType
) => {
  const data = { ...mock, timestamp: mock.created_at };

  if (type === 'add') {
    return [...chatData, data];
  }

  if (type === 'cancel') {
    return chatData.filter(({ id }) => id !== data.id);
  }

  const replacedMessage: IChatMessage = {
    ...data,
    status: status || 'sent',
  };

  const replacedChat = chatData.map((chat) =>
    chat.created_at === replacedMessage.created_at ? replacedMessage : chat
  );

  return replacedChat.find(({ created_at }) => created_at === replacedMessage.created_at)
    ? replacedChat
    : [...replacedChat, replacedMessage];
};

export const getViewsMessagesIds = (payload: { chat_id: string; chatData: chatDataType; email: string }) => {
  const { chatData, email, chat_id } = payload;

  const messages_id: string[] = [];
  const lastIndex = chatData[chat_id].data.length - 1;

  if (chatData[chat_id].data[lastIndex]?.email === email) return messages_id;

  for (let idx = lastIndex; idx >= 0; idx--) {
    if (chatData[chat_id].data[idx]?.email === email) break;

    messages_id.push(chatData[chat_id].data[idx].id!);
  }

  return messages_id;
};

const splitToGroup = (newChatData: IChatMessage[]) => {
  const splittedByGroups: IChatMessage[][] = [[]];
  let splittedIdx = 0;

  newChatData.forEach((chat, idx) => {
    splittedByGroups[splittedIdx].push(chat);
    if (!dateChecker(chat.created_at, newChatData[idx + 1]?.created_at || chat.created_at)) {
      splittedIdx += 1;
      splittedByGroups[splittedIdx] = [];
    }
  });

  return splittedByGroups;
};

const transformChatHistoryToChatData = (data: any[], chat_id: string, room_id: string): IChatMessage[] => {
  return data.map((data) => ({
    id: data.id,
    type: 'chat',
    timestamp: data.created_at,
    user_name: data.author.name,
    message: data.content,
    email: data.author.email,
    created_at: data.created_at,
    room_id,
    chat_id,
    status: data.status || 'sent',
    replied_to: data.replied_to,
  }));
};

const chatTitleSelection = (chats: IChat[], ownerId: string, oponentName?: string): IChat[] => {
  return chats.map((chat) => {
    if (chat.entity_type === 2 || chat.entity_type === 3) {
      const opponentUser = chat.members.find(({ id }) => id !== ownerId);
      return {
        ...chat,
        entity_name: chat.members.length === 2 || oponentName ? oponentName || opponentUser?.name! : chat.entity_name,
        title: chat.members.length === 2 || oponentName ? opponentUser?.email! : chat.title,
      };
    }

    if (chat.entity_type === 1) {
      return {
        ...chat,
        entity_name: chat.room.name || chat.entity_name,
        title: chat.room.name || chat.entity_name,
      };
    }

    return chat;
  });
};

const createRoomChat = async (payload: createChatPayloadType) => {
  const response = await api.createChat(payload.data);
  const transformedChat = chatTitleSelection([response.data], payload.ownerId, payload.oponentName)[0];
  return { chat: transformedChat, isForPush: true };
};

const createDocumentsChat = async (
  payload: createChatPayloadType,
  roomAllChats: IChat[],
  chatData: any,
  dispatch: any
) => {
  const sameChat = roomAllChats.find((chat) => chat.entity_id === payload.data.entity_id);
  const documentChat = !sameChat ? await createRoomChat(payload).catch((err) => null) : null;
  if (sameChat && !chatData[sameChat?.id].data.length) {
    await dispatch(fetchChatHistory({ chat_id: sameChat.id, room_id: payload.data?.room_id! }));
  }
  return { chat: sameChat ? sameChat : documentChat?.chat!, isForPush: !sameChat };
};

const createUserChat = async (payload: createChatPayloadType, roomAllChats: IChat[], chatData: any, dispatch: any) => {
  const sameChat = roomAllChats.find(
    (chat) =>
      chat.entity_type === 2 &&
      chat.members.length === 2 &&
      chat.members?.find(({ id }) => id === payload.data.members_ids[1])
  );

  if (payload.data.members_ids.length === 2 && sameChat) {
    const selectedChat = roomAllChats.find(({ id }) => id === sameChat.id);

    if (!chatData[sameChat.id].isLoadedHistory) {
      await dispatch(fetchChatHistory({ chat_id: sameChat.id, room_id: selectedChat?.room_id! }));
    }
    return { chat: selectedChat!, isForPush: false };
  }

  return await createRoomChat(payload);
};

const chatsTypes = [createDocumentsChat, createRoomChat, createUserChat];

const fetchChatData = createAsyncThunk(
  'chat/fetchData',
  async (payload: { data: IChatMessage; type: 'replace' | 'add' | 'cancel'; status?: messageStatusType }, thunkApi) => {
    const { dispatch } = thunkApi;
    const state = thunkApi.getState() as RootState;
    const { chatData, checkingMessages, selectedChat } = state.chat;
    const { userData } = state.userData;
    const newChatData = getCheckedChat(payload, chatData[payload.data.chat_id].data, payload.status);

    const splittedByGroups: IChatMessage[][] = splitToGroup(newChatData);

    try {
      if (payload.type === 'replace' && !payload.status) {
        dispatch(setAttemptCount(0));
        const filteredCheckingMessages = checkingMessages.filter(
          ({ created_at }) => created_at !== payload.data.created_at
        );
        dispatch(setCheckingMessages(filteredCheckingMessages));
        if (payload.data.email !== userData?.email && selectedChat?.id === payload.data.chat_id)
          dispatch(setViewsMessagesIds([payload.data.id!]));
      }
    } catch (Err) {
      console.log(Err);
    }

    return {
      data: newChatData,
      grouped: splittedByGroups,
      chatId: payload.data.chat_id,
    };
  }
);

const fetchChatId = createAsyncThunk(
  'fetch/chatId',
  async (payload: { ownerId: string; roomId: string; chatId: null | string }, thunkApi) => {
    try {
      // const { dispatch } = thunkApi;
      const response = (await api.getRoomChats(payload.roomId)).data
        .map((chat) => (chat.id === payload.chatId || chat.entity_type === 1 ? { ...chat } : chat))
        .sort((first, second) => (first.entity_type === 1 ? -1 : second.entity_type === 1 ? 1 : 0));

      const roomchatId = payload.chatId
        ? response.find(({ id }) => id === payload.chatId)!
        : response.find(({ entity_type }) => entity_type === 1)! || null;

      const transformedChats = chatTitleSelection(response, payload.ownerId);
      if (roomchatId === null) return { chats: transformedChats, history: [], splittedHistory: [], selectedChat: null };

      // await dispatch(fetchChatHistory({ room_id: payload.roomId, chat_id: roomchatId.id }));

      return {
        chats: transformedChats,
        selectedChat: { ...roomchatId, unviewed_amount: 0 },
      };
    } catch (err) {
      return thunkApi.rejectWithValue('Download Failed');
    }
  }
);

const fetchGlobalChats = createAsyncThunk(
  'fetch/globalChats',
  async (payload: { ownerId: string; chatId: null | string }, thunkApi) => {
    try {
      const { dispatch } = thunkApi;
      const state = thunkApi.getState() as RootState;
      const { userData } = state.userData;
      const checkingMessages = JSON.parse(localStorage.getItem(userData?.email!) || '[]');

      const response = (await api.getGLobalChats()).data
        .map((chat) => (chat.id === payload.chatId || chat.entity_type === 1 ? { ...chat, unviewed_amount: 0 } : chat))
        .sort((first, second) => (first.entity_type === 1 ? -1 : second.entity_type === 1 ? 1 : 0));

      const selectedChat = response[0];
      // selectedChat && (await dispatch(fetchChatHistory({ room_id: selectedChat.room_id, chat_id: selectedChat.id })));
      const transformedChats = selectedChat ? chatTitleSelection(response, payload.ownerId) : [];

      return {
        chats: transformedChats,
        selectedChat: selectedChat ? { ...selectedChat, unviewed_amount: 0 } : null,
        checkingMessages,
      };
    } catch (err) {
      console.log(err)
      return thunkApi.rejectWithValue('Download Failed');
    }
  }
);

const createGLobalChat = createAsyncThunk('create/globalChat', async (payload: createChatPayloadType, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { globalChats, chatData } = state.chat;

    const sameChat = globalChats.find(
      (chat) => chat.entity_type === 3 && chat.members?.find(({ id }) => id === payload.data.members_ids[1])
    );

    if (sameChat) {
      if (!chatData[sameChat.id].isLoadedHistory) {
        await dispatch(fetchChatHistory({ chat_id: sameChat.id, room_id: sameChat?.room_id! }));
      }
      return { chat: sameChat!, isForPush: false };
    }
    const formData = new FormData();
    formData.append('user_id', payload.data.members_ids[1]);

    const response = await api.createGLobalChat(formData);
    const transformedChat = chatTitleSelection([response.data], payload.ownerId)[0];

    return { chat: transformedChat, isForPush: true };
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to create global chat');
  }
});

const createChat = createAsyncThunk('fetch/createChat', async (payload: createChatPayloadType, thunkApi) => {
  try {
    const { dispatch } = thunkApi;
    const { roomAllChats, chatData } = (thunkApi.getState() as RootState).chat;

    return await chatsTypes[payload.data.entity_type](payload, roomAllChats, chatData, dispatch);
  } catch (err: any) {
    return thunkApi.rejectWithValue('Download Failed');
  }
});

const fetchChatHistory = createAsyncThunk(
  'fetch/chatHistory',
  async ({ chat_id, room_id }: { room_id: string; chat_id: string }, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const { dispatch } = thunkApi;
      const { checkingMessages } = state.chat;
      const response = await api.getChatHistory(chat_id);
      const filteredChecks = checkingMessages.filter(
        ({ created_at }) => !response.data.find((message) => message.created_at === created_at)
      );
      const chatMessageData = transformChatHistoryToChatData(response.data, chat_id, room_id).reverse();
      const splittedByGroups = splitToGroup(chatMessageData);
      dispatch(setCheckingMessages(filteredChecks));

      return {
        chat_id,
        history: chatMessageData,
        splittedHistory: splittedByGroups,
      };
    } catch (err: any) {
      return thunkApi.rejectWithValue('Download Failed');
    }
  }
);

const fetchChatInfo = createAsyncThunk(
  'fetch/chatInfo',
  async (payload: { chatId: string; ownerId: string }, thunkApi) => {
    try {
      const response = await api.getChatInfo(payload.chatId);
      const transformedChat = chatTitleSelection([response.data], payload.ownerId)[0];
      return transformedChat;
    } catch (Err) {
      return thunkApi.rejectWithValue('Failed to fetch chat info');
    }
  }
);

export const fetchUnviewedAmount = createAsyncThunk(
  'fetch/unviewedAmount',
  async ({ data, count }: { data: any; count: number }, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const { dispatch } = thunkApi;
      const { documents, folders } = state.documents;
      const { selectedChat } = state.chat;

      dispatch(
        setDocuments({
          documents: documents.map((doc) =>
            doc.id === data.entity_id
              ? {
                  ...doc,
                  unviewed_amount: Number(doc.unviewed_amount) + (count < 0 ? -Number(doc.unviewed_amount) : count),
                }
              : doc
          ),
          folders,
        })
      );
      selectedChat?.id !== (data.chat_id || data.id) &&
        dispatch(setUnviewedAmount({ count, chat_id: data.chat_id || data.id }));
    } catch (Err) {
      return thunkApi.rejectWithValue('Failed to fetch chat info');
    }
  }
);

export const setRejectMessages = createAsyncThunk(
  'chat/setRejecting',
  async (payload: { type?: StatusActions }, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const { chatData, checkingMessages, attemptCount } = rootState.chat;

      // const currentFilter = checkingMessages.filter(({ chat_id }) => chat_id === selectedChat?.id);
      // const rejectedChats = new Set(checkingMessages.map(({ chat_id }) => chat_id));
      const chatDataStore = JSON.parse(JSON.stringify(chatData)) as typeof chatData;
      let rejectedMessages: IChatMessage[] = checkingMessages.map((chat) => ({ ...chat, status: 'rejected' }));

      if (payload.type === StatusActions.REPLACE) {
        rejectedMessages.forEach((rejectedMessage) => {
          if (chatDataStore[rejectedMessage.chat_id]) {
            chatDataStore[rejectedMessage.chat_id].data = chatDataStore[rejectedMessage.chat_id].data.map((message) => {
              if (message.created_at === rejectedMessage.created_at) {
                rejectedMessages = rejectedMessages.filter(
                  ({ created_at }) => created_at !== rejectedMessage.created_at
                );
                return message;
              }

              return message;
            });
          }
        });

        rejectedMessages.forEach((rejectedMessage) => {
          if (chatDataStore[rejectedMessage.chat_id]) {
            chatDataStore[rejectedMessage.chat_id].data = [
              ...chatDataStore[rejectedMessage.chat_id].data,
              rejectedMessage,
            ].sort((a, b) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix());

            chatDataStore[rejectedMessage.chat_id].grouped = splitToGroup(chatDataStore[rejectedMessage.chat_id].data);
          }
        });
      } else {
        rejectedMessages.forEach((rejectedMessage) => {
          if (chatDataStore[rejectedMessage.chat_id]) {
            chatDataStore[rejectedMessage.chat_id].data = chatDataStore[rejectedMessage.chat_id].data.map((message) =>
              message.created_at === rejectedMessage.created_at ? rejectedMessage : message
            );

            chatDataStore[rejectedMessage.chat_id].grouped = splitToGroup(chatDataStore[rejectedMessage.chat_id].data);
          }
        });
      }

      const roomRejectedMessages = new Set(
        rejectedMessages.filter(({ type }) => type === 'room').map(({ chat_id }) => chat_id)
      );
      const mainRejectedMessages = new Set(
        rejectedMessages.filter(({ type }) => type === 'main').map(({ chat_id }) => chat_id)
      );

      return {
        chatData: chatDataStore,
        allRejectedMessages: [Array.from(roomRejectedMessages), Array.from(mainRejectedMessages)],
        rejectedMessages,
        isShowSupport: rejectedMessages.length >= attemptCount && attemptCount >= 3,
      };
    } catch (Err) {
      return thunkApi.rejectWithValue('Failed to fetch chat info');
    }
  }
);

export const setMessagesToLocalStorage = createAsyncThunk('chat/setToLocalStorage', async (payload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { checkingMessages } = state.chat;
    const { userData } = state.userData;

    localStorage.setItem(userData?.email!, JSON.stringify(checkingMessages));
  } catch (err) {
    console.log(err);
  }
});

export const fetchSetSelectedChat = createAsyncThunk('chat/fetchSetSelectedChat', async (payload: IChat, thunkApi) => {
  try {
    const { dispatch } = thunkApi;
    const state = thunkApi.getState() as RootState;
    const { chatData } = state.chat;
    const { userData } = state.userData;

    dispatch(setSelectedChatRoom(payload));
    const messagesViews = getViewsMessagesIds({ chat_id: payload.id, chatData, email: userData?.email! });
    if (messagesViews.length) dispatch(setViewsMessagesIds(messagesViews));
  } catch (err) {
    console.log(err);
  }
});

export const switchChatMessagesStatus = createAsyncThunk(
  'chat/switchChatMessagesStatus',
  async (payload: IChatMessage[], thunkApi) => {
    try {
      const { dispatch } = thunkApi;
      const state = thunkApi.getState() as RootState;
      const { chatData } = state.chat;
      const chatDataStore = JSON.parse(JSON.stringify(chatData)) as typeof chatData;

      if (!payload.length) return chatDataStore;
      const chatId = payload[0].chat_id;
      const messageIds = payload.map(({ id }) => id);
      chatDataStore[chatId].data = chatDataStore[chatId].data.map((message) =>
        messageIds.includes(message.id) ? { ...message, status: 'viewed' } : message
      );
      chatDataStore[chatId].grouped = splitToGroup(chatDataStore[chatId].data);

      dispatch(setChatData(chatDataStore));
      return chatDataStore;
    } catch (err) {
      console.log(err);
    }
  }
);

export default fetchChatData;
export { createChat, createGLobalChat, fetchChatHistory, fetchChatId, fetchChatInfo, fetchGlobalChats };
