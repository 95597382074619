import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchConfidentialDocuments, fetchRoom, fetchRoomNotifications } from 'store/reducers/roomsCreator';
import { IDataRoom, IDocument, INotification } from '../../interfaces';

interface DataState {
  dataRoom: IDataRoom | null;
  isLoadingRooms: boolean;
  errorRooms: string;

  needToUpdateRooms: boolean;

  confidentialDocuments: IDocument[];
  isLoadingConfidentialDocuments: boolean;
  errorLoadingConfidentialDocuments: string;

  isFolderCreateOpen: boolean;
  isLogsDetailsOpen: boolean;
  notifications: INotification[];
  notificationStatus: 'pending' | 'empty' | 'fulfilled';
  isLoadingNotifications: boolean;
}

const initialState: DataState = {
  dataRoom: null,
  isLoadingRooms: false,
  errorRooms: '',

  needToUpdateRooms: false,

  confidentialDocuments: [],
  isLoadingConfidentialDocuments: false,
  errorLoadingConfidentialDocuments: '',

  isFolderCreateOpen: false,
  isLogsDetailsOpen: false,
  notifications: [],
  notificationStatus: 'pending',
  isLoadingNotifications: false,
};

const dataRoomSlice = createSlice({
  name: 'dataRoom',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchRoom.fulfilled, (state, action: PayloadAction<IDataRoom>) => {
      state.isLoadingRooms = false;
      state.errorRooms = '';
      state.dataRoom = action.payload;
    });
    builder.addCase(fetchRoom.pending, (state) => {
      state.isLoadingRooms = true;
    });
    builder.addCase(fetchRoom.rejected, (state, action: any) => {
      state.isLoadingRooms = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorRooms = action.payload.errorMessage;
      } else {
        state.errorRooms = action.error.message;
      }
    });
    builder.addCase(fetchConfidentialDocuments.fulfilled, (state, action: PayloadAction<IDocument[]>) => {
      state.confidentialDocuments = action.payload;
      state.isLoadingConfidentialDocuments = false;
      state.errorLoadingConfidentialDocuments = '';
    });
    builder.addCase(fetchConfidentialDocuments.pending, (state) => {
      state.isLoadingConfidentialDocuments = true;
    });
    builder.addCase(fetchConfidentialDocuments.rejected, (state, action: any) => {
      state.isLoadingConfidentialDocuments = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorLoadingConfidentialDocuments = action.payload.errorMessage;
      } else {
        state.errorLoadingConfidentialDocuments = action.error.message;
      }
    });

    builder.addCase(fetchRoomNotifications.pending, (state, action) => {
      state.notificationStatus = 'pending';
      state.isLoadingNotifications = true;
    });

    builder.addCase(fetchRoomNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.isLoadingNotifications = false;
    });

    builder.addCase(fetchRoomNotifications.rejected, (state, action: any) => {
      state.isLoadingNotifications = false;
      if (action.message === 'canceled' || action.message === 'Aborted') return;
      state.notificationStatus = 'empty';
    });
  },
  reducers: {
    setDataRoom(state, action: PayloadAction<IDataRoom | null>) {
      state.dataRoom = action.payload;
    },
    setIsFolderCreateOpen(state, action: PayloadAction<boolean>) {
      state.isFolderCreateOpen = action.payload;
    },
    setIsLogsDetailsOpen(state, action: PayloadAction<boolean>) {
      state.isLogsDetailsOpen = action.payload;
    },
    setNotifications(state, action: PayloadAction<INotification[]>) {
      state.notifications = action.payload;
    },
    setNeedToUpdateRooms(state, action: PayloadAction<boolean>) {
      state.needToUpdateRooms = action.payload;
    },
    setNotificationsStatus(state, action: PayloadAction<'pending' | 'empty' | 'fulfilled'>) {
      state.notificationStatus = action.payload;
    },
    setIsLoadingNotifications(state, action: PayloadAction<boolean>) {
      state.isLoadingNotifications = action.payload;
    }
  },
});

const dataRoomReducer = dataRoomSlice.reducer;

export const {
  setDataRoom,
  setIsFolderCreateOpen,
  setIsLogsDetailsOpen,
  setNotifications,
  setNeedToUpdateRooms,
  setNotificationsStatus,
  setIsLoadingNotifications
} = dataRoomSlice.actions;
export default dataRoomReducer;
